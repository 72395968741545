<template>
    <div class="container">
        <div class="main">
            <form id="form" class="form">
                <div>
                    <p>Wyślij wiadomość do</p>
                    <p>{{ receiver}}</p>
                </div>
                <input id="kontakt" type="text" name="kontakt" v-model="kontakt" placeholder="Dane kontaktowe: e-mail lub numer telefonu" required/>
                <input id="tytul" type="text" name="tytul" v-model="tytul" placeholder="Temat" required/>
                <textarea id="tresc" type="text" name="tresc" v-model="tresc" placeholder="Wiadomość" required></textarea>
                <button>Wyślij wiadomość</button>
            </form>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import { useRouter } from 'vue-router';
import { reactive, toRefs, onMounted, inject } from 'vue';


    var rzeczy = reactive({
        receiver: "",
        kontakt: "",
        tytul: "",
        tresc: "",
    })
    export default
    {
        props:
        {
            id:
            {
            },
            ogloszenieInfo:
            {

            }
        },
        setup(props)
        {
            const endpoint = inject('g_endpoint');
            const router = useRouter();
            function getCookie(cname) 
            {
            let name = cname + "=";
            let ca = document.cookie.split(';');
            for(let i = 0; i < ca.length; i++) {
                let c = ca[i];
                while (c.charAt(0) == ' ') {
                c = c.substring(1);
                }
                if (c.indexOf(name) == 0) {
                return c.substring(name.length, c.length);
                }
            }
            return "";
            }

            const fetchData = async () => 
            {
                try {
                    const response = await axios.get(`${endpoint}api/offers/${props.ogloszenieInfo}`);
                    var offer = response.data;
                    var response2 = await axios.get(`${endpoint}api/users/${offer.user}`);
                    var receiver = response2.data;
                    rzeczy.receiver = receiver.name;

                } 
                catch (error) {
                    console.error('Błąd podczas pobierania danych z API:', error);
                }
            };

            async function wyslij(event)
            {
                event.preventDefault();
                const response = await axios.get(`${endpoint}api/offers/${props.ogloszenieInfo}`);
                var offer = response.data;
                var response2 = await axios.get(`${endpoint}api/users/${offer.user}`);
                var receiver = response2.data;
                var cos = {
                    sender: getCookie('userId'),
                    reciver: receiver.id,
                    related_offer: offer.id,
                    email: rzeczy.kontakt,
                    title: rzeczy.tytul,
                    content: rzeczy.tresc
                }
                let cosG = JSON.stringify(cos);
                console.log(cosG);
                await axios
                .post(`${endpoint}api/messages/`, cosG, {
                withCredentials: true,
                headers: {
                    'X-CSRFToken': `${getCookie('tokenCSRF')}`,
                    'Authorization': `Token ${getCookie('tokenDRF')}`,
                    'Content-Type': "application/json"
                }
                })
                .catch(error => {console.error(error)})
                .then(() => {
                    router.back();
                })
                var ilosc = {
                    user: receiver.id,
                    number_of_opinions: offer.number_of_opinions - -1,
                }
                var iloscG = JSON.stringify(ilosc);
                await axios
                .put(`${endpoint}api/offers/${props.ogloszenieInfo}/`, iloscG, {
                withCredentials: true,
                headers: {
                    'X-CSRFToken': `${getCookie('tokenCSRF')}`,
                    'Authorization': `Token ${getCookie('tokenDRF')}`,
                    'Content-Type': "application/json"
                }
                })
                .catch(error => {console.error(error)})
            }
            
            onMounted(() => 
            {
                fetchData();
                document.getElementById('form').addEventListener('submit', wyslij);
            });
            
            return{...toRefs(rzeczy)}
        }
    }
</script>

<style lang="scss" scoped>
    @import '../styleFolder/style.scss';
    .container
    {
        margin-top: 15vh;
        display: flex;
        justify-content: center;
    }
    .main
    {
        width: 80vw;
        min-height: 80vh;
        & .form
        {
            display: flex;
            flex-direction: column;
            gap: 5vh;
            & button 
            {
                width: 60%;
                align-self: center;
                font-size: 1rem;
                font-weight: bold;
                color: white;
                padding: 3%;
                margin-bottom: 7vh;
                //align-self: center;
                text-align: center;
                background-color: $main-color;
                border-radius: 75px;
                border: 1px solid white;
                cursor: pointer;
                //font-size: 1vw;
                //font-weight: bold;
                transition: 0.3s;
                text-decoration: none;
                &:hover {
                    color: $main-color;
                    background-color: white;
                    border: 1px solid $main-color;
                }
            }
            & div 
            {
                text-align: center;
                font-size: 2rem;
                display: flex;
                flex-direction: column;
                gap: 2vh;
            }
            & input, select 
            {
                //width: calc(100% - 4vh);
                height: 5vh;
                background-color: white;
                border-radius: 75px;
                border: 1px solid $border-color;
                text-align: left;
                padding-left: 5%;
                padding-right: 5%;
                font-size: 1rem;
                color: $font-color;
                outline: none;
            }
            & textarea 
            {
                //width: calc(100% - 4vh);
                outline: none;
                overflow: hidden;
                overflow-wrap: break-word;
                resize: none;
                min-height: 25vh;
                //margin-top: 2vh;
                border-radius: 30px;
                border: 1px solid $border-color;
                padding: 2vh;
            }
        }
    }
    
    @media only screen and (min-width: 600px) 
    {
        .main
        {
            width: 60vw;
        }
    }

    @media only screen and (min-width: 1000px) 
    {
        .main
        {
            width: 40vw;
        }
    }
</style>