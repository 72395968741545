<template>
    <div class="container">
        <div class="main">
            <div class="main-header">
                <h1>Dlaczego warto wybrać GigaKorki?</h1>
            </div>
            <div class="main-opinie" v-for="element in opinie.slice(0,ile)" :key="element">
                <p class="imie">{{ element.imie }}</p>
                <p class="opis">{{ element.opis }}</p>
            </div>
            <div class="main-more" v-if="ile<opinie.length">
                <button @click="zwiekszLimit">Więcej opinii</button>
            </div>
        </div>
    </div>
</template>

<script>
    import {ref} from "vue";
    var ile = ref(6);
    function zwiekszLimit()
    {
        ile.value+=10;
    }
    var opinie = ref(
        [
            {
                "imie": "Miron",
                "opis": "Dzięki tej stronie znalazłem korepetytora z matematyki dla syna. Bardzo dobrze tłumaczył i pomógł poprawić jego oceny. Polecam!"
            },
            {
                "imie": "Katarzyna",
                "opis": "Znalazłam tutaj świetnego nauczyciela angielskiego. Lekcje były ciekawe i pomogły mi lepiej mówić po angielsku. Serdecznie polecam!"
            },
            {
                "imie": "Rafał",
                "opis": "Szukanie nauczyciela do chemii było łatwe, a efekty widać w szkole. Bardzo pomocny serwis!"
            },
            {
                "imie": "Sara",
                "opis": "Znalazłam tutaj nauczyciela gry na pianinie, który na nowo rozbudził moją pasję do muzyki. Świetnie naucza!"
            },
            {
                "imie": "Janek",
                "opis": "Bardzo przydatna strona! Pomogła mojemu synowi w nauce biologii. Jego oceny znacznie się poprawiły."
            },
            {
                "imie": "Edward",
                "opis": "Mój syn miał problem z fizyką, ale znaleziony tu nauczyciel świetnie wytłumaczył materiał i pomógł mu zrozumieć trudne zagadnienia."
            },
            {
                "imie": "Ada",
                "opis": "Świetny serwis do znajdowania nauczycieli. Moja córka teraz lubi fizykę i czuje się pewniej w szkole."
            },
            {
                "imie": "Julia",
                "opis": "Dzięki tej stronie mój syn zaczął się interesować historią, co przyniosło mu lepsze oceny. Bardzo polecam!"
            },
            {
                "imie": "Marek",
                "opis": "Udało mi się znaleźć świetnego nauczyciela matematyki. Nauka z nim to czysta przyjemność. Polecam wszystkim!"
            },
            {
                "imie": "Anna",
                "opis": "Matematyka już nie jest dla mnie straszna. Nauczyciel z tej strony świetnie tłumaczył trudne kwestie."
            },
            {
                "imie": "Tomasz",
                "opis": "Znalazłem tu dobrego nauczyciela angielskiego, który super przekazał mi wiedzę. Teraz swobodnie mówię po angielsku."
            },
            {
                "imie": "Katarzyna",
                "opis": "Na tej stronie znalazłam nauczyciela biologii, który pokazał mi, jak ciekawy może być ten przedmiot."
            },
            {
                "imie": "Piotr",
                "opis": "Dzięki tej stronie biologia stała się dla mnie ciekawa. Nauczyciel zaintrygował mnie przedmiotem."
            },
            {
                "imie": "Magdalena",
                "opis": "Na tej stronie znalazłam nauczyciela, który otworzył przede mną nowe horyzonty w biologii."
            },
            {
                "imie": "Marcin",
                "opis": "Tutaj znalazłem nauczyciela, który pomógł mi zrozumieć trudne zagadnienia z języka polskiego. Świetne przygotowanie do egzaminu!"
            },
            {
                "imie": "Julia",
                "opis": "Znalazłam tu nauczyciela chemii, który z pasją tłumaczył materiał. Teraz to mój ulubiony przedmiot."
            },
            {
                "imie": "Michał",
                "opis": "Korepetytor z ekonomii z tej strony zainteresował mnie przedmiotem w sposób, którego się nie spodziewałem. Teraz to moje hobby."
            },
            {
                "imie": "Natalia",
                "opis": "Dzięki tej stronie polubiłam geografię. Nauczyciel przedstawił ją w bardzo interesujący sposób."
            },
            {
                "imie": "Adam",
                "opis": "Tutaj znalazłem nauczyciela programowania, który był świetny. Teraz rozwijam moje umiejętności programistyczne."
            },
            {
                "imie": "Ewa",
                "opis": "Znalazłam nauczyciela fizyki, który jasno tłumaczył skomplikowane zagadnienia. Moje wyniki znacznie się poprawiły."
            },
            {
                "imie": "Bartosz",
                "opis": "Nauczyciel z tej strony wykazał się dużym zaangażowaniem i cierpliwością, co pomogło mi w nauce."
            },
            {
                "imie": "Monika",
                "opis": "Znalazłam tutaj świetnego nauczyciela angielskiego. Zajęcia były pełne interesujących rozmów, które pomogły mi płynnie mówić po angielsku."
            }
        ]
        )
    export default
    {
        setup()
        {
            return {opinie, ile, zwiekszLimit};
        }
    }
</script>

<style lang="scss" scoped>
    @import '../styleFolder/style.scss';
    .container
    {
        display: flex;
        justify-content: center;
    }
    .main
    {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-evenly;
        padding-top: 5vh;
        padding-bottom: 5vh;
        row-gap: 5vh;
        width: 90%;
        &-opinie
        {
            width: 100%;
            height: auto;
            display: flex;
            justify-content: center;
            flex-direction: column;
            border: 1px solid $border-color;
            padding: 5%;
            padding-top: 2%;
            padding-bottom: 2%;
            & .imie
            {
                line-height: 3rem;
                font-size: 1.4rem;
            }
            & .opis
            {
                font-style:italic;
                font-weight: lighter;
            }
        }
        &-more
        {
            width: 80vw;
            text-align: center;
            margin-bottom: 10vh;
            & button
            {
                color: white;
                width: 40%;
                height: 200%;
                background-color: $main-color;
                border-radius: 75px;
                border: 1px solid $main-color;
                cursor: pointer;
                font-size: 1rem;
                font-weight: bold;
                transition: 0.3s;
                text-decoration: none;
                user-select: none;
                &:hover
                {
                    color: $main-color;
                    background-color: white;
                    border: 1px solid $main-color;
                }
            }
        }
        &-header
        {
            width: 70%;
            text-align: center;
        }
    }

    @media only screen and (min-width: 600px)
    {

    }

    @media only screen and (min-width: 1000px)
    {

    }
</style>