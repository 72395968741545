<template>
    <div class="container">
        Newsletter
    </div>
</template>

<script>

</script>

<style lang="scss" scoped>
    .container
    {
        margin-top: 10vh;
    }
    
    @media only screen and (min-width: 600px) 
    {

    }

    @media only screen and (min-width: 1000px) 
    {

    }
</style>