<template>
    <div class="zarobki">
        <div class="z-main">
            <h3>Pracuj na WŁASNYCH warunkach</h3>
            <h1>Zobacz, jak dużo możesz zarobić udzielając korepetycji na GigaKorki.pl</h1>
            <div>
                <h4>{{ z1 }} zł/60 min</h4>
                <input type="range" v-model="z1" min="30" max="500" step="10">
                <h4>{{ z2 }} lekcji w tygodniu</h4>
                <input type="range" v-model="z2" min="1" max="50">
            </div>
            <h4><h1>{{ kwota }}</h1> zł/miesiąc</h4>
            <div>
                <router-link to="rejestracja">utwórz konto</router-link>
            </div>
        </div>
    </div>
</template>

<script>
import { computed, ref } from 'vue';

    var z1 = ref(50);
    var z2 = ref(10);
    var kwota = computed(() => z1.value*z2.value*4);
    export default
    {
    setup() {
        return { z1, z2, kwota };
    },
}
</script>

<style lang="scss" scoped>
    @import '../styleFolder/style.scss';
    .zarobki
    {
        width: 100vw;
        display: flex;
        justify-content: center;
        margin: auto;
    }
    .z-main
    {
        width: 100vw;
        display: flex;
        flex-direction: column;
        gap: 5vh;
        border: 1px solid $main-color;
        border-radius: 5vh;
        padding: 5vh 5vw;
        & div 
        {
            display: flex;
            flex-direction: column;
            justify-content: space-evenly;
            gap: 2vh;
            & a
            {
                text-decoration: none;
                display: flex;
                justify-content: center;
                align-items: center;

                height: 100%;
                line-height: 3rem;
                border-radius: 100px;
                border: 1px solid $main-color;
                background-color: $main-color;
                color: white;
                font-size: 1.2rem;
                font-weight: bold;
                transition: 0.3s ease-out;
                &:hover 
                {
                    background-color: white;
                    color: $main-color;
                    border:1px solid $main-color;
                    cursor: pointer;
                }
            }
        }
        & h1, & h3, & h4
        {
            text-align: center;
            display: inline;
        }
        & h4
        {
            font-size: 1.3rem;
        }
    }
    
    @media only screen and (min-width: 600px) 
    {

    }

    @media only screen and (min-width: 1000px) 
    {
        .zarobki
        {
            width: 90vw;
        }
    }
</style>