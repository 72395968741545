<template>
    <div class="prawy">
        <div class="dodaj">
            <form class="dodaj-form" id="form" autofill="off">
                <h1 v-if="!offerId">Dodawanie ogłoszenia</h1>
                <h1 v-else>Edycja ogłoszenia</h1>
                <div class="dodaj-form-div-1">
                    <div>
                        <label for="tytul" class="tytul">Tytuł ogłoszenia</label>
                        <div>
                            <input type="text" name="tytul" id="tytul" class="tytul" v-model="tytul" placeholder="Tytuł Twojego ogłoszenia"/>
                        </div>
                    </div>
                </div>
                <div class="dodaj-form-div-2">
                    <div class="header">
                        <label for="opcje">Ustawienia podstawowe</label>
                    </div>
                    <div class="rzeczy">
                        
                        <div class="dane">
                            <label for="przedmiot">Przedmiot</label><br>
                            <select id="przedmiot" name="przedmiot" v-model="przedmiot" required>
                                <option value="" hidden>Wybierz przedmiot</option>
                                <option v-for="(element, index) in przedmioty" :key="element" :value="przedmiotyNr[index]">{{ element }}</option>
                            </select>
                        </div>
                        <div class="dane">
                            <label for="mode">Tryb</label><br>
                            <select id="mode" name="mode" v-model="mode" required>
                                <option value="" hidden>Wybierz, czy szukasz, czy udzielasz korepetycji</option>
                                <option value="student">Szukam korepetytora</option>
                                <option value="tutor">Udzielę korepetycji</option>
                            </select>
                        </div>
                        <div class="dane">
                            <label for="cena">Cena za lekcję <a class="error">{{ cenaError }}</a></label><br>
                            <input type="text" id="cena" name="cena" placeholder="Podaj cenę za lekcję" v-model="cena" required/>
                        </div>
                        <div class="dane">
                            <label for="miejsce">Długość lekcji</label><br>
                            <select id="miejsce" name="miejsce" v-model="dlugosc" required>
                                <option value="" hidden>Wybierz długość lekcji</option>
                                <option value="20">20 minut</option>
                                <option value="30">30 minut</option>
                                <option value="45">45 minut</option>
                                <option value="60">60 minut</option>
                                <option value="90">90 minut</option>
                            </select>
                        </div>
                        <div class="zakres">
                            <label for="zakres">Miejsce pracy</label><br>
                            <div id="zakres">
                                <div>
                                    <input type="checkbox" id="miejsce-0" name="miejsce" class="miejsce" v-model="online" required/>
                                    <label for="miejsce-0">Online</label>
                                </div>
                                <div>
                                    <input type="checkbox" id="miejsce-1" name="miejsce" class="miejsce" v-model="uUcznia" required/>
                                    <label for="miejsce-1">U ucznia</label>
                                </div>
                                <div>
                                    <input type="checkbox" id="miejsce-2" name="miejsce" class="miejsce" v-model="uKorepetytora" required/>
                                    <label for="miejsce-2">U korepetytora</label>
                                </div>
                            </div>
                        </div>
                        <div class="zakres">
                            <label for="zakres">Zakres materiału</label><br>
                            <div id="zakres">
                                <div>
                                    <input type="checkbox" id="level-0" name="level" class="level" v-model="przedszkole" required/>
                                    <label for="level-0">Przedszkole</label>
                                </div>
                                <div>
                                    <input type="checkbox" id="level-1" name="level" class="level" v-model="szkolaPodstawowa" required/>
                                    <label for="level-1">Szkoła podstawowa</label>
                                </div>
                                <div>
                                    <input type="checkbox" id="level-2" name="level" class="level" v-model="egzaminOsmoklasisty" required/>
                                    <label for="level-2">Egzamin ósmoklasisty</label>
                                </div>
                                <div>
                                    <input type="checkbox" id="level-3" name="level" class="level" v-model="szkolaSrednia" required/>
                                    <label for="level-3">Szkoła średnia</label>
                                </div>
                                <div>
                                    <input type="checkbox" id="level-4" name="level" class="level" v-model="matura" required/>
                                    <label for="level-4">Matura</label>
                                </div>
                                <div>
                                    <input type="checkbox" id="level-5" name="level" class="level" v-model="studia" required/>
                                    <label for="level-5">Studia</label>
                                </div>
                                <div>
                                    <input type="checkbox" id="level-6" name="level" class="level" v-model="inne" required/>
                                    <label for="level-6">Inne</label>
                                </div>
                            </div>
                        </div>
                        <div class="zakres">
                            <label for="zakres">Dostępność<a style="visibility:hidden; color:red; " id="chk_option_error">>huju</a></label><br>
                            <div id="zakres">
                                <div>
                                    <input type="checkbox" id="dost-0" name="dost" v-model="poniedzialek" required/>
                                    <label for="dost-0">Poniedziałek</label>
                                </div>
                                <div>
                                    <input type="checkbox" id="dost-1" name="dost" v-model="wtorek" required/>
                                    <label for="dost-1">Wtorek</label>
                                </div>
                                <div>
                                    <input type="checkbox" id="dost-2" name="dost" v-model="sroda" required/>
                                    <label for="dost-2">Środa</label>
                                </div>
                                <div>
                                    <input type="checkbox" id="dost-3" name="dost" v-model="czwartek" required/>
                                    <label for="dost-3">Czwartek</label>
                                </div>
                                <div>
                                    <input type="checkbox" id="dost-4" name="dost" v-model="piatek" required/>
                                    <label for="dost-4">Piątek</label>
                                </div>
                                <div>
                                    <input type="checkbox" id="dost-5" name="dost" v-model="sobota" required/>
                                    <label for="dost-5">Sobota</label>
                                </div>
                                <div>
                                    <input type="checkbox" id="dost-6" name="dost" v-model="niedziela" required/>
                                    <label for="dost-6">Niedziela</label>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                </div>
                <div class="dodaj-form-div-3">
                    <div>
                        <label for="tytul">Treść ogłoszenia</label>
                        <div>
                            <textarea id="tresc" name="tresc" placeholder="Treść Twojego ogłoszenia" v-model="opis"></textarea>
                        </div>
                    </div>
                </div>
                <div class="dodaj-form-div-4">
                    <div class="dodaj-form-4-button-div" >
                        <button type="submit" id="submit" name="submit">Zapisz</button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
    import router from "@/router";
    import { useRoute } from 'vue-router';
    import axios from "axios";
    import { reactive, ref, toRefs, onMounted, computed, inject, watch} from "vue";
    var przedmioty = ref([]);
    var przedmiotyNr = ref([]);
    var formularz = reactive(
        {
            tytul: '',
            user: '',
            id: '',
            mode: '',
            przedmiot: '',
            cena: '',
            dlugosc: '',
            opis: '',

            online: false,
            uUcznia: false,
            uKorepetytora: false,

            przedszkole: false,
            szkolaPodstawowa: false,
            egzaminOsmoklasisty: false,
            szkolaSrednia: false,
            matura: false,
            studia: false,
            inne: false,

            levels: computed(() => {
                let levels = '';
                if(formularz.przedszkole) levels += '1';
                if(formularz.szkolaPodstawowa) levels += '2';
                if(formularz.egzaminOsmoklasisty) levels += '3';
                if(formularz.szkolaSrednia) levels += '4';
                if(formularz.matura) levels += '5';
                if(formularz.studia) levels += '6';
                if(formularz.inne) levels += '7';
            return levels;
            }),

            poniedzialek: false,
            wtorek: false,
            sroda: false,
            czwartek: false,
            piatek: false,
            sobota: false,
            niedziela: false,
            
            dni: computed(() => {
                let dni = '';
                if(formularz.poniedzialek) dni += 'pn';
                if(formularz.wtorek) dni += 'wt';
                if(formularz.sroda) dni += 'śr';
                if(formularz.czwartek) dni += 'cz';
                if(formularz.piatek) dni += 'pt';
                if(formularz.sobota) dni += 'sb';
                if(formularz.niedziela) dni += 'nd';
            return dni;
            }),
        })
    let cenaError = ref();
    export default
    {
        name: 'stronaKontaDodajOgloszenie',
        props: 
        {

        },
        setup()
        {
            const endpoint = inject('g_endpoint');
            const route = useRoute();
            let offerId = route.query.offerId;
        onMounted(() => {
            function getCookie(cname) 
            {
            let name = cname + "=";
            let ca = document.cookie.split(';');
            for(let i = 0; i < ca.length; i++) {
                let c = ca[i];
                while (c.charAt(0) == ' ') {
                c = c.substring(1);
                }
                if (c.indexOf(name) == 0) {
                return c.substring(name.length, c.length);
                }
            }
            return "";
            }
            async function fetchuj()
            {
                const response = await axios.get(`${endpoint}api/subjects/`);
                let subjects = response.data;
                subjects.forEach(element => {
                    przedmioty.value.push(element.name);
                    przedmiotyNr.value.push(element.id);
                })

                const response2 = await axios.get(`${endpoint}api/users/${getCookie('userId')}`);
                if (response2.data.name==null)
                {
                    alert("Najpierw uzupełnij swoje dane");
                    router.push('/konto/ustawienia');
                }
            }
            fetchuj();

            async function fetchData(oId)
            {
                const response = await axios.get(`${endpoint}api/offers/${offerId}/`);
                let oferta = response.data;
                const response2 = await axios.get(`${endpoint}api/subjects/${oferta.subject}/`);
                let przedmiot = response2.data;

                formularz.tytul = oferta.title;
                formularz.user = oferta.user;
                formularz.id = oId;
                formularz.mode = oferta.mode;
                formularz.przedmiot = przedmiot.id;
                formularz.cena = oferta.hour_price;
                formularz.dlugosc = oferta.lesson_time;
                formularz.online = oferta.is_online;
                formularz.uUcznia = oferta.is_stationary_at_student;
                formularz.uKorepetytora = oferta.is_stationary_at_tutor;
                formularz.opis = oferta.description;

                let dni = oferta.when_he_can;
                if(dni.includes('pn')) formularz.poniedzialek = true;
                if(dni.includes('wt')) formularz.wtorek = true;
                if(dni.includes('śr')) formularz.sroda = true;
                if(dni.includes('cz')) formularz.czwartek = true;
                if(dni.includes('pt')) formularz.piatek = true;
                if(dni.includes('sb')) formularz.sobota = true;
                if(dni.includes('nd')) formularz.niedziela = true;

                let levels = oferta.levels;
                if(levels.includes('1')) formularz.przedszkole = true;
                if(levels.includes('2')) formularz.szkolaPodstawowa = true;
                if(levels.includes('3')) formularz.egzaminOsmoklasisty = true;
                if(levels.includes('4')) formularz.szkolaSrednia = true;
                if(levels.includes('5')) formularz.matura = true;
                if(levels.includes('6')) formularz.studia = true;
                if(levels.includes('7')) formularz.inne = true;

                var dost = document.getElementsByName("dost");
                var miejsce = document.getElementsByName("miejsce");
                var level = document.getElementsByName("level");
                deRequireStart(dost,miejsce,level);
            }

            async function fetchData2()
            {
                formularz.tytul = '';
                formularz.user = '';
                formularz.id = '';
                formularz.mode = '';
                formularz.przedmiot = '';
                formularz.cena = '';
                formularz.dlugosc = '';
                formularz.online = false;
                formularz.uUcznia = false;
                formularz.uKorepetytora = false;
                formularz.opis = '';
                formularz.przedszkole = false;
                formularz.szkolaPodstawowa = false;
                formularz.egzaminOsmoklasisty = false;
                formularz.szkolaSrednia = false;
                formularz.matura = false;
                formularz.studia = false;
                formularz.inne = false;

                formularz.poniedzialek = false;
                formularz.wtorek = false;
                formularz.sroda = false;
                formularz.czwartek = false;
                formularz.piatek = false;
                formularz.sobota = false;
                formularz.niedziela = false;
            }

            if(offerId) 
            {
                fetchData(offerId);
                //document.getElementById('dodajOgloszenieLink').style.color="#1a9ddb";
            }
            else fetchData2();

            

            function getReady()
            {
                document.getElementById('form').addEventListener('submit', postuj);

                var rzeczy = document.getElementsByName("level");
                var dost = document.getElementsByName("dost");
                var miejsce = document.getElementsByName("miejsce");
                rzeczy.forEach(element => 
                {
                    element.addEventListener("click", () => deRequireCb(rzeczy));
                });
                dost.forEach(element => 
                {
                    element.addEventListener("click", () => deRequireCb(dost));
                });
                miejsce.forEach(element => 
                {
                    element.addEventListener("click", () => deRequireCb(miejsce));
                });

                
            }

            getReady();
            
            const ograniczCene = (newValue) => {
            if (parseInt(newValue) > 999) {
                formularz.cena = 999;
            }
            };
            const ograniczTytul = (newValue) => {
            if (newValue.length > 70) {
                formularz.tytul = newValue.slice(0, 70);
            }
            };
            

            watch(() => formularz.cena, ograniczCene);
            watch(() => formularz.tytul, ograniczTytul);

            async function postuj(event)
            {
                event.preventDefault();
                console.log(toRefs(formularz));
                var cos = {
                        user: getCookie('userId'),
                        subject: formularz.przedmiot,/////////
                        is_online: formularz.online,
                        is_stationary_at_student: formularz.uUcznia,
                        is_stationary_at_tutor: formularz.uKorepetytora,
                        description: formularz.opis,
                        hour_price: formularz.cena,
                        when_he_can: formularz.dni,
                        mode: formularz.mode,
                        title: formularz.tytul,
                        lesson_time: formularz.dlugosc,
                        levels: formularz.levels,
                    }
                let cosG = JSON.stringify(cos);
                console.log(cosG);
                if(!offerId)
                {
                    await axios
                    .post(`${endpoint}api/offers/`, cosG, {
                    withCredentials: true,
                    headers: {
                        'X-CSRFToken': `${getCookie('tokenCSRF')}`,
                        'Authorization': `Token ${getCookie('tokenDRF')}`,
                        'Content-Type': "application/json"
                    }
                    })
                    .then(() => 
                    {
                        cenaError.value = '';
                        router.push('/konto');
                    })
                    .catch(error => {
                        console.error(error);
                        cenaError.value = "cena musi być liczbą";
                    })
                }
                else 
                {
                    await axios
                    .put(`${endpoint}api/offers/${formularz.id}/`, cosG, {
                    withCredentials: true,
                    headers: {
                        'X-CSRFToken': `${getCookie('tokenCSRF')}`,
                        'Authorization': `Token ${getCookie('tokenDRF')}`,
                        'Content-Type': "application/json"
                    }
                    })
                    .then(() => 
                    {
                        cenaError.value = '';
                        router.push("/konto");
                    })
                    .catch(error => {
                        console.error(error);
                        cenaError.value = "cena musi być liczbą";
                    })
                }
            }

            function deRequireStart(dost, miejsce, levels)// do ogarnięcia dla leveli
            {
                console.log("huje");
                if(formularz.dni)
                {
                    for (let i = 0; i < dost.length; i++) 
                    {
                        dost[i].required = false;
                        console.log(dost[i].required);
                    }
                }
                if(formularz.levels)
                {
                    for (let i = 0; i < levels.length; i++) 
                    {
                        levels[i].required = false;
                        console.log(levels[i].required);
                    }
                }
                if(formularz.online || formularz.uUcznia || formularz.uKorepetytora)
                {
                    for (let i = 0; i < miejsce.length; i++) 
                    {
                        miejsce[i].required = false;
                    }
                }
            }

            function deRequireCb(name)
            {
                let atLeastOneChecked = false; //at least one cb is checked

                for (let i = 0; i < name.length; i++) 
                {
                    if (name[i].checked === true) 
                    {
                        atLeastOneChecked = true;
                    }
                }

                if (atLeastOneChecked === true) 
                {
                    for (let i = 0; i < name.length; i++) 
                    {
                        name[i].required = false;
                    }
                } 
                else 
                {
                    for (let i = 0; i < name.length; i++) {
                        name[i].required = true;
                    }
                } 
            }
            return {deRequireCb, deRequireStart};
        });
        
        return{przedmioty, przedmiotyNr, cenaError, ...toRefs(formularz), offerId}
        }
    }
</script>

<style lang="scss" scoped>
    @import '../styleFolder/style.scss';

    *
    {
        box-sizing: border-box;
    }
    select:invalid
    {
        color: $border-color2 !important;
        opacity: 1;
    }
    input:invalid
    {
        color: $border-color2 !important;
        opacity: 1;
    }
    input::placeholder
    {
        color: $border-color2 !important;
        opacity: 1;
    }
    .dodaj-form 
    {
        display: flex;
        flex-direction: column;

        h1 
        {
            margin-bottom: 5vh;
            text-align: center;
        }

        &-div-1 
        {
            margin-bottom: 2vh;

            & div label 
            {
                font-size: 1.5rem;
                font-weight: bold;
                width: 100%;
                text-align: center;
                display: inline-block;
            }

            & div div 
            {
                border: 1px solid $border-color;
                box-shadow: 0.5vh 0.5vh 1vh 1vh $border-color;
                padding: 2%;
                border-radius: 1vh;
                margin-top: 2vh;

                & input 
                {
                    width: 100%;
                    height: 5vh;
                    background-color: white;
                    border-radius: 1vh;
                    border: 1px solid $border-color;
                    text-align: left;
                    padding-left: 2%;
                    font-size: 1rem;
                    color: $font-color;
                    outline: none;
                }
            }
        }

        &-div-2 
        {
            margin-top: 3vh;

            & .header 
            {
                & label 
                {
                    font-size: 1.5rem;
                    font-weight: bold;
                    width: 100%;
                    text-align: center;
                    display: inline-block;  
                }
            }

            & .dane 
            {
                margin-bottom: 8%;
                text-align: center;
                width: 100%;
                & label 
                {
                    display: block;
                    text-align: left;
                    padding-left: 1vw;
                    & a.error
                    {
                        color: red;
                    }
                }

                & input, select 
                {
                    width: 95%;
                    height: 75%;
                    background-color: white;
                    border-radius: 100vw;
                    border: 1px solid $border-color;
                    padding-left: 5%;
                    font-size: 1rem;
                    color: $font-color;
                    outline: none;
                }
            }

            & .rzeczy 
            {
                display: flex;
                flex-wrap: wrap;

                border: 1px solid $border-color;
                box-shadow: 0.5vh 0.5vh 1vh 1vh $border-color;
                padding-top: 3vh;
                padding-bottom: 4vh;
                padding-left: 1vh;
                padding-right: 1vh;
                border-radius: 1vh;
                margin-top: 3vh;
                margin-bottom: 5vh;

                & div 
                {
                    margin-top: 1vh;
                }

                & .zakres 
                {
                    margin-top: 3vh;
                    padding-left: 2vh;
                    padding-right: 2vh;
                    width: 100%;

                    & div 
                    {
                        & div 
                        {
                            padding: 1vh;

                            & label 
                            {
                                margin-left: 0.7rem;
                            }
                        }
                    }
                }
            }
        }

        &-div-3 
        {
            margin-bottom: 2vh;

            & div label 
            {
                font-size: 1.5rem;
                font-weight: bold;
                width: 100%;
                text-align: center;
                display: inline-block;
            }

            & div div 
            {
                border: 1px solid $border-color;
                box-shadow: 0.5vh 0.5vh 1vh 1vh $border-color;
                padding: 2vh;
                border-radius: 1vh;
                margin-top: 2vh;
                text-align: center;

                & textarea 
                {
                    width: 100%;
                    background-color: white;
                    border-radius: 1vh;
                    border: 1px solid $border-color;
                    padding-left: 2%;
                    padding-right: 2%;
                    font-size: 1rem;
                    color: $font-color;
                    outline: none;
                    resize: vertical;
                    min-height: 20vh;
                }
            }
        }

        &-div-4 
        {
            & div 
            {
                text-align: center;
                margin-top: 2vh;

                & button 
                {
                    width: 50%;
                    color: white;
                    padding: 1.5%;
                    background-color: $main-color;
                    border-radius: 100vw;
                    border: 1px solid $main-color;
                    cursor: pointer;
                    font-size: 1rem;
                    font-weight: bold;
                    transition: 0.3s;
                    text-decoration: none;

                    &:hover {
                        color: $main-color;
                        background-color: white;
                    }
                }
            }
        }
    }
    
    @media only screen and (min-width: 600px) 
    {
        .dodaj-form 
        {
            h1 
            {
                margin-bottom: 5%;
                text-align: left;
            }

            &-div-1 
            {
                margin-bottom: 2%;

                & div label 
                {
                    text-align: left;
                }
            }

            &-div-2 
            {
                margin-top: 3%;

                & .header label
                {
                    text-align: left;
                }
            
            }
            &-div-3 
            {
                & div label 
                {
                    text-align: left;
                }
            }
        }
    }

    @media only screen and (min-width: 1000px) 
    {
        .dodaj-form 
        {
            h1 
            {
                margin-bottom: 5vh;
            }

            &-div-1 
            {
                & div div 
                {
                    padding: 1%;
                }
            }

            &-div-2 
            {

                & .dane 
                {
                    margin-bottom: 8vh;
                }

                & .rzeczy 
                {

                    & div 
                    {
                        width: 50%;
                        margin-top: 1vh;
                    }

                    & .zakres 
                    {
                        & div 
                        {
                            display: flex;
                            flex-wrap: wrap;
                            width: 100%;

                            & div 
                            {
                                width: 33%;
                            }
                        }
                    }
                }
            }
        }
    }
</style>
