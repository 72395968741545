<template>
<div v-show='sprawdz' v-if="0" class="kk">a</div>
<div v-show="sprawdz" v-if="0" class="linkers"><router-link to="/konto/link" id="warning-tekst">Zobacz, jak otrzymać nawet kilkanaście tysięcy złotych na konto za polecanie GigaKorków!</router-link><a id="warning-close">✕</a></div>
	<div class="baner" id="baner">
	<div class="baner-logo">
		<router-link to="/" class="baner-logo-img"><img src="./logo10.png"></router-link>
	</div>
	<div class="baner-login-button">
		<router-link v-if="isLoggedIn" to="/konto" class="baner-login-button-a">Konto</router-link>
		<router-link v-else to="/logowanie" class="baner-login-button-a">Zaloguj</router-link>
	</div>
	<div class="baner-register-button">
		<router-link v-if="isLoggedIn" to="/konto/dodajOgloszenie" class="baner-login-button-a">Nowe ogłoszenie</router-link>
		<router-link v-else to="/rejestracja" class="baner-login-button-a">Zarejestruj</router-link>
	</div>
	</div>
	<a class="marg"></a>
</template>

<script>
import { computed, ref } from 'vue';
import { useStore } from 'vuex';

export default {
setup() {
	const store = useStore();
	const isLoggedIn = computed(() => store.state.isLoggedIn);
	var sprawdz = ref(true);
	/* function setCookie(cname, cvalue, exhours) {
	const d = new Date();
	d.setTime(d.getTime() + (exhours * 60 * 60 * 1000));
	let expires = "expires=" + d.toUTCString();
	document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
	}
	function getCookie(cname)
	{
	let name = cname + "=";
	let ca = document.cookie.split(';');
	for(let i = 0; i < ca.length; i++) {
		let c = ca[i];
		while (c.charAt(0) == ' ') {
		c = c.substring(1);
		}
		if (c.indexOf(name) == 0) {
		return c.substring(name.length, c.length);
		}
	}
	return "";
	} */
	/* function closeW()
	{
	setCookie('pokazWarning', false, 1/2 );
	document.getElementById('baner').style.marginTop = 0;
	sprawdz.value=false;
	}
	onMounted(() => {

	let x = document.getElementById('warning-close');
	x.addEventListener('click', closeW);
	if(getCookie('pokazWarning')) closeW();
	}) */
	return {sprawdz, isLoggedIn}
},
created() {
	console.log('isLoggedIn:', this.isLoggedIn);
},
};
</script>

<style lang="scss" scoped>
	@import '../styleFolder/style.scss';
	*
	{
		box-sizing: border-box;
	}
	.baner-login-button-a
	{
		color: white;
		width: auto;
		padding: 10px 20px;
		background-color: $main-color;
		border-radius: 75px;
		border: none;
		cursor: pointer;
		font-size: 1rem;
		white-space: nowrap;
		font-weight: bold;
		transition: 0.3s;
		text-decoration: none;
		user-select: none;

		&:hover
		{
			color: $main-color;
			background-color: white;
		}
	}
	.kk
	{
		height: 5vh;
	}
	.linkers
	{
		position: fixed;
		z-index: 10000;
		margin-bottom: 5vh;
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding-left: 2%;
		padding-right: 2%;
		height: 5vh;
		width: 100vw;
		transform: translateY(-5vh);
		background-color: $warning-color;
		& a
		{
			color: white;
			cursor:pointer;
			user-select: none;
			text-decoration: none;
		}
	}
	.baner
	{
		display: grid;
		//margin-top: 5vh;
		grid-template-columns: 40% auto auto 5%;
		grid-template-areas:
		'logo btn1 btn2 .';
		grid-template-rows: 100px;
		align-items: center;
		position: fixed;
		top: 0;
		z-index: 500;
		width: 100vw;
		height: 100px;
		//max-height: 100px;
		background-image: linear-gradient(#202545, #15172e);
		box-shadow: 0px 0px 2px 2px #15172e;

		&-logo-img
		{
			height: 60%;
			display: flex;
			align-items: center;
			justify-content: center;
			background-color: transparent;
			user-select: none;
			&:hover
			{
				background-color: transparent;
			}

			& img
			{
				height: 100%;
			}
		}

		&-logo
		{
			grid-area: logo;
			display: flex;
			height: 100%;
			align-items: center;
		}

		&-login-button
		{
			grid-area: btn1;
			text-align: right;
		}

		&-register-button
		{
			grid-area: btn2;
			text-align: right;
		}
	}

	@media only screen and (min-width: 600px)
	{
	.baner-login-button-a
	{
		padding: 10px 20px;
	}
	.baner
	{
		grid-template-columns: 20% 40% 10% 25% 5%;
		grid-template-areas:
		'logo . btn1 btn2 .';
		grid-template-rows: 100%;
		height: 100px;
		&-logo-img
		{
		height: 100%;
		}
		&-logo-img img
		{
		height: 100%;
		}
	}
	}

	@media only screen and (min-width: 1000px)
	{
	.baner
	{
		grid-template-columns: 10% 5% 50% 10% 15% 10%;
		grid-template-areas:
		'. logo . btn1 btn2 .';
	}
	}
</style>