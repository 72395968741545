<template>
    <stronaPodgladuBody/>
</template>

<script>
    import stronaPodgladuBody from './stronaPodgladuFolder/stronaPodgladuBody.vue';
    export default 
    {
        name: 'stronaPodgladu',
        components:
        {
            stronaPodgladuBody,

        },
        setup()
        {
            document.title = 'Korepetycje z...';
        }
    }
</script>

<style lang="scss" scoped>
    *
    {
        margin-top: 15vh;
    }
</style>