<template>
    <div class="main">
        <h1 class="main-element">Formularz Rejestracyjny</h1>
        <form class="main-form" id="form">
            <div class="main-form-div">
                <label for="email" class="main-form-div-label">Adres e-mail {{ glicz }}</label>
                <div>
                    <input id="email" type="email" name="email" v-model="email" required>
                </div>
            </div>
            <div class="main-form-div">
                <label for="haslo" class="main-form-div-label">Hasło</label>
                <div>
                    <input id="haslo" type="password" name="haslo" v-model="haslo" required>
                </div>
            </div>
            <div class="main-form-div">
                <label for="potw-haslo" class="main-form-div-label">Potwierdź hasło</label>
                <div>
                    <input id="potw-haslo" type="password" name="potw-haslo" v-model="potwhaslo" required>
                </div>
            </div>
            <div class="main-form-div-regulamin main-form-div">
                <div>
                    <input id="regulamin" type="checkbox" name="regulamin" value="regulamin" required>
                    Akceptuję <RouterLink to="/regulamin">regulamin serwisu</RouterLink> oraz <RouterLink to="/prywatnosc">politykę prywatności</RouterLink> i <RouterLink to="/polityka">plików cookies</RouterLink>
                </div>
            </div>
            <div class="main-form-div-rejestracja main-form-div">
                <div>
                    <button type="submit" id="subBtn">Rejestracja</button>
                </div>
            </div>
        </form>
    </div>
</template>

<script>
    import {ref, inject} from "vue";
    import axios from "axios";
    import router from "@/router";

    var email = ref('');
    var haslo = ref('');
    var potwhaslo = ref('');
    var glicz = ref('');
    var czyOk = ref(1);
    export default
    {
        name: 'stronaRejestracjiBody',
        mounted()
        {
            const endpoint = inject('g_endpoint');
            var info = new FormData();
            
            document.getElementById("form").addEventListener("submit", register)
            async function register(event)
            {
                event.preventDefault();
                //alert("submit");
                info.append("email", email.value);
                info.append("password", haslo.value);
                czyOk.value = 1;
                if(haslo.value!=potwhaslo.value)
                {
                    alert("Hasła nie są identyczne");
                    return;
                }
                await axios
                .post(`${endpoint}register/`, info)
                .catch(error => 
                {
                    glicz.value = error.response.status;
                    czyOk.value = 0;
                })
                .then(response => 
                {
                    glicz.value = 0;
                    if(czyOk.value) router.push("/logowanie");
                    else glicz.value = "Mail już istnieje";
                    console.log(response);
                })
                //router.push("/logowanie")
                
                
            }
            //Axios.defaults.headers.common['X-CSRF-TOKEN'] = token;
            
        },

        setup() 
        {
            return {email,haslo,glicz,potwhaslo}
        }
    }
</script>
<style lang="scss" scoped>
    @import '../styleFolder/style.scss';
  *
  {
      margin: 0;
      padding: 0;
      user-select: none;
      font-family: Arial, Helvetica, sans-serif;
  }
  .main
  {
      min-height: 90vh;
      width: 100%;   
      padding-top: 15vh;
      &-element
      {
        text-align: center;
      }
      &-form
      {
        left:50%;
        //text-align: center;
        max-width: 500px;
        position: relative;
        transform: translateX(-50%);
        height: 75%;
        &-div
        {
            margin-top: 5vh;
            &-regulamin
            {
                text-align: center;
            }
            &-rejestracja
            {
                text-align: center;
                & button
                {
                    width: 105%;
                    height: 7vh;
                    border-radius: 100px;
                    border: none;
                    background-color: $main-color;
                    color: white;
                    font-size: 1rem;
                    font-weight: bold;
                    transition: 0.3s ease-out;
                    &:hover 
                    {
                        background-color: white;
                        color: $main-color;
                        border:1px solid $main-color;
                        cursor: pointer;
                    }
                }
            }
            
        }
      }
      & input
      {
        width: 100%;
        height: 7vh;
        margin-top: 2vh;
        background-color: white;
        border-radius: 75px;
        text-align: left;
        padding-left: 5%;
        font-size: 1rem;
        color: $font-color;
        border: 1px solid $border-color;
        outline: none;
      }
      & label
      {
        padding-left: 5%;
      }
  }
  #regulamin
  {
    height: auto;
    width: auto;
  }
  a
  {
    color: $main-color;
  }
    
    @media only screen and (min-width: 600px) 
    {

    }

    @media only screen and (min-width: 1000px) 
    {

    }
</style>
