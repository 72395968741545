<template>
    <div class="prawy">
        Historia zamówień
    </div>
</template>

<script>
    export default
    {
        name: 'stronaKontaHistoriaZamowien',

    }
</script>

<style lang="scss" scoped>
    @import '../styleFolder/style.scss';
    .container
    {
        margin-top: 15vh;
    }
    
    @media only screen and (min-width: 600px) 
    {

    }

    @media only screen and (min-width: 1000px) 
    {

    }
</style>