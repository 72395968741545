<template>
    <div class="ad">
        <div class="tytul">
            {{ ogloszenie }}
        </div>
        <div class="akcje">
            <div class="ludzie">
                <a class="ludzieA">
                    Od: {{ odKogo }}
                </a>
                <a class="ludzieA">
                    Do: {{ doKogo }}
                </a>
            </div>
            <div class="tytulOgloszenia">
                <a class="tytulOgloszeniaA">
                    Tytuł wiadomości: {{ tytul }}
                </a>
            </div>
            <div class="kontakt">
                <a class="kontaktA">
                    Dane kontaktowe: {{ kontakt }}
                </a>
            </div>
            <div class="tresc">
                <a :id="`trescA${$props.info.id}`" class="aA">
                    {{ tresc }}
                </a>
            </div>
            
        </div>
        <div class="more">
            <a class="more-btn" @click="rozwin">Rozwiń więcej▼</a>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import { onMounted, reactive, toRefs, inject } from 'vue';

    export default
    {
        props:
        {
            info:
            {

            }
        },
        setup(props)
        {
            const endpoint = inject('g_endpoint');
            var wiadomosc = reactive({
                id: '',
                odKogo: '',
                doKogo: '',
                ogloszenie: '',
                kontakt: '',
                tytul: '',
                tresc: ''
            })
            function getCookie(cname) 
            {
                let name = cname + "=";
                let ca = document.cookie.split(';');
                for(let i = 0; i < ca.length; i++) {
                    let c = ca[i];
                    while (c.charAt(0) == ' ') {
                    c = c.substring(1);
                    }
                    if (c.indexOf(name) == 0) {
                    return c.substring(name.length, c.length);
                    }
                }
                return "";
            }
            getCookie('userId');
            async function fetchData()
            {
                const senderResponse = await axios.get(`${endpoint}api/users/${props.info.sender}/`)
                let sender = senderResponse.data;
                const receiverResponse = await axios.get(`${endpoint}api/users/${props.info.reciver}/`)
                let receiver = receiverResponse.data;
                const adResponse = await axios.get(`${endpoint}api/offers/${props.info.related_offer}/`)
                let ad = adResponse.data;
                wiadomosc.id = props.info.id;
                wiadomosc.odKogo = sender.name;
                wiadomosc.doKogo = receiver.name;
                wiadomosc.ogloszenie = ad.title;
                wiadomosc.kontakt = props.info.email;
                wiadomosc.tytul = props.info.title;
                wiadomosc.tresc = props.info.content;

            }
            function rozwin()
            {
                if(document.getElementById(`trescA${props.info.id}`).style.height!='auto')document.getElementById(`trescA${props.info.id}`).style.height='auto';
                else document.getElementById(`trescA${props.info.id}`).style.height='10vh';
            }
            onMounted(() => {
                fetchData();
            })
            return {...toRefs(wiadomosc), rozwin}
        }
    }
</script>

<style lang="scss" scoped>
    @import '../styleFolder/style.scss';
    *
    {
        color: $font-color;
        text-decoration: none;
        box-sizing: border-box;
    }
    .ad
    {
        width: 100%;
        //height: 40vh;
        background-color: transparent;
        display: flex;
        flex-wrap: wrap;
        //gap: 2vh;
        border-radius: 2vh;
        margin-top: 5%;
        margin-bottom: 10vh;
        border: solid 1px $border-color;
        & div
        {
            padding: 1%;
            padding-left: 2%;
            padding-right: 2%;
        }
        & .tytul
        {
            width: 100%;
            height: 5vh;
            background-color: $main-color;
            color: white;
            border-top-left-radius: 2vh;
            border-top-right-radius: 2vh;
            display: flex;
            padding: 2%;
            align-items: center;
        }
        & .akcje
        {
            width: 100%;

            & .ludzie
            {
                width: 100%;
                display: flex;
                justify-content: space-evenly;
            }
            & .aA
            {
                height: 10vh;
                display: inline-block;
                text-overflow: clip;
                overflow: hidden;
            }
        }
        & .more
        {
            width: 100%;
            display: flex;
            justify-content: center;
            padding-bottom: 1%;
        }
    }
    
    @media only screen and (min-width: 600px) 
    {

    }

    @media only screen and (min-width: 1000px) 
    {
        
    }
</style>