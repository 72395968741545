<template>
    <div class="container">
        <div class="main">
            <div class="main-osoba">
                <div class="main-osoba-1">
                    <img :src=" awatar "/>
                </div>
                <div class="main-osoba-2">
                    <p id="korepetytor">{{ przedmiot }}</p>
                    <h3>{{ username }}{{ disabled }}</h3>
                </div>
                <div class="main-osoba-3">
                    <router-link :to="{name: 'wiadomosc', params: {id: $props.id, ogloszenieInfo:$props.ogloszenieInfo}}" class="linkers" v-if="disabled">Wyślij wiadomość</router-link>
                    <a :to="{name: 'wiadomosc', params: {id: $props.id, ogloszenieInfo:$props.ogloszenieInfo}}" class="linkers linkers-wywal" v-else>Wyślij wiadomość</a>
                </div>
            </div>
            <div class="main-info">
                <div class="main-info-telefon">
                    <strong>Tefefon:</strong> {{ telefon }}
                </div>
                <div class="main-info-miejsce">
                    <strong>Miejsce:</strong> <a>{{ online ? "online," : '' }}{{ uUcznia ? " u Ucznia," : '' }}{{ uKorepetytora ? " u Korepetytora" : '' }}</a>
                </div>
                <div class="main-info-miasto">
                    <strong>Miasto:</strong> {{ miasto }}
                </div>
                <div class="main-info-dostepnosc">
                    <strong>Dostępność:</strong> {{ dostepnosc }}
                </div>
                <div class="main-info-discord">
                    <strong>Discord:</strong> {{ discord }}
                </div>
                <div class="main-info-skype">
                    <strong>Skype:</strong> {{ skype }}
                </div>
                <div class="main-info-zoom">
                    <strong>Zoom:</strong> {{ zoom }}
                </div>
                <div class="main-info-fb">
                    <strong>Facebook:</strong> {{ facebook }}
                </div>
            </div>
            <div class="main-tytul">
                <h1> {{ tytul }}</h1>
            </div>
            <div class="main-tresc">
                <p>
                    {{ opis }}
                </p>
            </div>
            <div class="main-info2">
                <div class="main-info2-div">
                    <div class="main-info2-div-cena">
                        {{ cena }}
                    </div>
                </div>
                <div class="main-info2-div">
                    <div class="main-info2-div-wyksztalcenie">
                        <strong>Wykształcenie: </strong>{{ wyksztalcenie }}
                    </div>
                </div>
                <!-- <div class="main-info2-div">
                    <div class="main-info2-div-zawod">
                        <strong>Zawód: </strong>{{ zawod }}
                    </div>
                </div> -->
                <div class="main-info2-div">
                    <div class="main-info2-div-doswiadczenie">
                        <strong>Doświadczenie: </strong>{{ doswiadczenie }}
                    </div>
                </div>
                <!-- <div class="main-info2-div">
                    <strong>Udzielam też korepetycji z:</strong>
                    <div class="main-info2-div-pozostale" v-for="nazwa in pozostaleKorepetycje" :key="nazwa">
                        <p>{{ nazwa.przedmiot }}</p> {{ nazwa.cena }}
                    </div>
                </div> -->
            </div>
        </div>
    </div>
</template>

<script>
    import {ref, onMounted, reactive, toRefs, inject} from "vue";
    import axios from "axios";
    import { RouterLink } from "vue-router";
    export default 
    {
    name: 'stronaPodgladuBody',
    props: 
    {
        id: {
            type: String,
        },
        ogloszenieInfo: 
        {
        //type: Object,
        }
    },
    setup(props)
        {
            
            const endpoint = inject('g_endpoint');
            function getCookie(cname) 
            {
            let name = cname + "=";
            let ca = document.cookie.split(';');
            for(let i = 0; i < ca.length; i++) {
                let c = ca[i];
                while (c.charAt(0) == ' ') {
                c = c.substring(1);
                }
                if (c.indexOf(name) == 0) {
                return c.substring(name.length, c.length);
                }
            }
            return "";
            }
            var disabled = ref();
            disabled.value = getCookie('czyZalogowano');
            //alert(getCookie('czyZalogowano'));
            var ogloszenie = reactive(
            {
                id: "",
                userId: "",
                awatar: "",
                username: "",
                tytul: "",
                miasto: "",/////////////
                przedmiot: "",
                miejsce:"",/////////
                online: "",
                uUcznia: "",
                uKorepetytora: "",

                cena: "",////////////////
                dlugoscLekcji: "",
    
                telefon: "",
                discord: "",
                skype: "",
                zoom: "",
                facebook: "",
                
                wyksztalcenie: "",
                zawod: "",
                doswiadczenie: "",
    
                pozostale: "",
    
                zakres: "", ///////////
                /* {
                    //tutaj znajdzie się max 7 rzeczy:
                    przedszkole: element.zakres,
                    szkołaPodstawowa: element.zakres,
                    egzaminOsmoklasisty: element.zakres,
                    szkołaSrednia: element.zakres,
                    matura: element.zakres,
                    studia: element.zakres,
                    inne: element.zakres
                }, */
                dostepnosc: "",
                /* {
                    //tak samo jak zakres
                    poniedziałek: element.dostepnosc,
                    wtorek: element.dostepnosc,
                    sroda: element.dostepnosc,
                    czwartek: element.dostepnosc,
                    piatek: element.dostepnosc,
                    sobota: element.dostepnosc,
                    niedziela: element.dostepnosc
                }, */
                opis: "",
                /* wiadomosci:
                [
                    {
                        od: "",
                        do: "",
                        email: "",
                        tytul: "",
                        tresc: ""
                    },
                    //kolejne wiadomości
                ], */
                wyroznioneDo: "",
                zweryfikowany: "",
                wyswietlenia: "",
                /* statystyki:
                {
                    wyswietlenia: 0,
                    wiadomosci: 0
                } */
            });
            //ogloszenie.opis = "sss";
            var user = ref();
            const fetchData = async () => 
            {
                try {
                    //const response2 = await axios.get(`http://localhost:8000/api/users/`);
                    const response = await axios.get(`${endpoint}api/offers/${props.ogloszenieInfo}`);
                    let element = response.data;
                    const response2 = await axios.get(`${endpoint}api/users/${element.user}`);
                    let user = response2.data;
                    const response3 = await axios.get(`${endpoint}api/subjects/${element.subject}`);
                    let subject = response3.data;
                    const response4 = await axios.get(`${endpoint}api/regions/${parseInt(user.occupation) + 1}/`);
                    let miasto = response4.data;
                    

                        ogloszenie.id = element.id;
                        ogloszenie.userId = element.user;

                        ogloszenie.awatar = require('../stronaOgloszeniaFolder/Obraz1.jpg');
                        ogloszenie.username = user.name;
                        ogloszenie.tytul = element.title;
                        ogloszenie.przedmiot = subject.name;

                        ogloszenie.dostepnosc = element.when_he_can;//////////

                        ogloszenie.online = element.is_online,
                        ogloszenie.uUcznia = element.is_stationary_at_student,
                        ogloszenie.uKorepetytora = element.is_stationary_at_tutor,

                        ogloszenie.miasto = miasto.city;
                        ogloszenie.cena = element.hour_price + " zł";
                        ogloszenie.dlugoscLekcji = element.lesson_time;


                        ogloszenie.telefon = user.phone_number,
                        ogloszenie.discord = user.discord,////////////
                        ogloszenie.skype = user.skype,///////////
                        ogloszenie.zoom = user.zoom,///////////
                        ogloszenie.facebook = user.facebook,//////////
                           
                        ogloszenie.wyksztalcenie = user.education,
                        //ogloszenie.zawod = user.occupation,//
                        ogloszenie.doswiadczenie = user.experience,

                        ogloszenie.pozostale = "",///////////////////
                        ogloszenie.zakres = user.when_he_can_teach,
                        ogloszenie.opis = element.description;
                        ogloszenie.wyroznioneDo = element.outstanding_to;
                        ogloszenie.zweryfikowany = user.verified_to;
                        ogloszenie.wyswietlenia = element.number_of_views + 1;

                        /*                             
                            przedmiot: subject.name,
                            level: element.levels,
                            mode: element.mode,

                            telefon: user.phone_number,

                            wyksztalcenie: user.education,
                            doswiadczenie: user.experience,

                            online: element.is_online,
                            uUcznia: element.is_stationary_at_student,
                            uKorepetytora: element.is_stationary_at_tutor,

                            cena: element.hour_price,
                            dlugoscLekcji: element.lesson_time,
                            
                            dostepnosc: element.when_he_can,
                           
                            opis: element.description,
                            
                            wyroznioneDo: wyroznioneF(element),
                            zweryfikowany: zweryfikowanyF(user),

                            wyswietlenia: element.number_of_views,
                            wiadomosci: element.number_of_opinions, */
                } 
                catch (error) {
                    console.error('Błąd podczas pobierania danych z API:', error);
                }
                var cos = {
                    user: ogloszenie.userId,
                    number_of_views: ogloszenie.wyswietlenia,
                }
                await axios
                .put(`${endpoint}api/offers/${props.ogloszenieInfo}/`, JSON.stringify(cos) , {
                withCredentials: true,
                headers: {
                    'X-CSRFToken': `${getCookie('tokenCSRF')}`,
                    'Authorization': `Token ${getCookie('tokenDRF')}`,
                    'Content-Type': "application/json"
                }
                })
                .then((response) => 
                {
                    console.log(response);
                    //cenaError.value = '';
                })
                .catch(error => console.log(error))
            };
            
            onMounted(() => 
            {
                fetchData();
            });
            console.log(ogloszenie)
            return { ...toRefs(ogloszenie), user,disabled };
        },
    components: { RouterLink }
}
</script>

<style lang="scss" scoped>
    @import '../styleFolder/style.scss';
    *
    {
        box-sizing: border-box;
    }
    .container 
    {
        margin-top: 10vh;
        color: $font-color;
    }
    .main {
        display: grid;
        grid-template-columns: 50% 50%;
        grid-template-rows: auto;
        min-height: 60vh;
        justify-content: center;
        grid-template-areas: 
            "osoba osoba"
            "info2 info2"
            "info info"
            "tytul tytul"
            "tresc tresc";
        &-info2 {
            grid-area: info2;
            border-left: 1px solid $border-color;
            padding: 5%;
            display: flex;
            flex-direction: column;
            &-div {
                //border-bottom: 1px solid $border-color;
                padding: 2%;
                &-cena {
                    grid-area: cena;
                    background-color: $main-color;
                    border-radius: 2vh;
                    height: auto !important;
                    min-height: 3rem !important;
                    color: white;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    font-size: 3rem;
                    font-weight: bold;
                    border: 1px solid $main-color;
                }
                &-pozostale {
                    margin-top: 2% !important;
                    margin-bottom: -1% !important;
                }
                &-doswiadczenie {
                    margin-bottom: -1% !important;
                }
                & div {
                    min-height: 5vh;
                    margin: 1vh 0;
                }
                & p {
                    color: $border-color2;
                    font-size: 0.75rem;
                }
            }
        }
        &-osoba {
            grid-area: osoba;
            padding: 2%;
            display: grid;
            grid-template-columns: 40% 60%;
            grid-template-rows: 60% 40%;
            grid-template-areas: 
                "img korepetytor"
                "button button";
            gap: 2vw;
            align-items: center;
            border-bottom: 1px solid $border-color;
            border-right: 1px solid $border-color;
            &-1 {
                grid-area: img;
            }
            &-2 {
                grid-area: korepetytor;
            }
            &-3 {
                grid-area: button;
                display: flex;
                align-items: center;
                justify-content: center;
            }
            & img {
                width: 100%;
                border-radius: 100vw;
            }
            & #korepetytor {
                color: $border-color2;
            }
            & a.linkers-wywal
            {
                background-color: $border-color2 !important;
                &:hover
                {
                    color: white !important;
                    background-color: $border-color !important;
                    border: 1px solid $border-color !important;
                }
            }
            & a.linkers 
            {
                user-select: none;
                width: 60%;
                color: white;
                padding: 3%;
                margin-bottom: 7vh;
                //align-self: center;
                text-align: center;
                background-color: $main-color;
                border-radius: 75px;
                border: 1px solid white;
                cursor: pointer;
                //font-size: 1vw;
                //font-weight: bold;
                transition: 0.3s;
                text-decoration: none;
                &:hover {
                    color: $main-color;
                    background-color: white;
                    border: 1px solid $main-color;
                }
            }
        }
        &-tytul {
            grid-area: tytul;
            //overflow-x: auto;
            //overflow-y: visible;
            //white-space: nowrap;
            overflow-x: scroll;
            overflow-y: hidden;
            white-space: nowrap;
            height: 100%;
            //overflow-wrap: break-word;
            //word-break: break-all;
            display: flex;
            align-items: center;
            padding: 7%;
            padding-bottom: 0;
            & h1 {
                font-weight: bolder;
            }
        }
        &-info {
            grid-area: info;
            border-right: 1px solid $border-color;
            display: flex;
            flex-direction: column;
            gap: 1rem;
            padding: 5%;
        }
        &-tresc {
            grid-area: tresc;
            padding: 7%;
            & p {
                font-size: 1rem;
                overflow-wrap: break-word;
            }
        }
    }
    
    @media only screen and (min-width: 600px) 
    {

    }

    @media only screen and (min-width: 1000px) 
    {
        .container
        {
            margin-top: 120px;
        }
        .main 
        {
            display: grid;
            grid-template-columns: 20% 40% 20%;
            grid-template-rows: auto 20vh auto;
            min-height: 60vh;
            justify-content: center;
            grid-template-areas: 
                "osoba tytul info2"
                "osoba tresc info2"
                "info tresc info2";
            &-info2 {
                grid-area: info2;
                border-left: 1px solid $border-color;
                padding: 5%;
                display: flex;
                flex-direction: column;
                &-div {
                    border-bottom: 1px solid $border-color;
                    padding: 2%;
                    &-cena {
                        grid-area: cena;
                        background-color: $main-color;
                        border-radius: 2vh;
                        height: 10% !important;
                        color: white;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        font-size: 3rem;
                        font-weight: bold;
                        border: 1px solid $main-color;
                    }
                    &-pozostale {
                        margin-top: 2% !important;
                        margin-bottom: -1% !important;
                    }
                    &-doswiadczenie {
                        margin-bottom: -1% !important;
                    }
                    & div {
                        min-height: 5vh;
                        margin: 1vh 0;
                    }
                    & p {
                        color: $border-color2;
                        font-size: 0.75rem;
                    }
                }
            }
            &-osoba {
                grid-area: osoba;
                padding: 2%;
                display: grid;
                grid-template-columns: 40% 60%;
                grid-template-rows: 60% 40%;
                grid-template-areas: 
                    "img korepetytor"
                    "button button";
                gap: 2vw;
                align-items: center;
                border-bottom: 1px solid $border-color;
                border-right: 1px solid $border-color;
                &-1 {
                    grid-area: img;
                }
                &-2 {
                    grid-area: korepetytor;
                }
                &-3 {
                    grid-area: button;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
                & img {
                    width: 100%;
                    border-radius: 100vw;
                }
                & #korepetytor {
                    color: $border-color2;
                }
                & a.linkers {
                    width: 60%;
                    color: white;
                    padding: 3%;
                    margin-bottom: 7vh;
                    //align-self: center;
                    text-align: center;
                    background-color: $main-color;
                    border-radius: 75px;
                    border: 1px solid white;
                    cursor: pointer;
                    //font-size: 1vw;
                    //font-weight: bold;
                    transition: 0.3s;
                    text-decoration: none;
                    &:hover {
                        color: $main-color;
                        background-color: white;
                        border: 1px solid $main-color;
                    }
                }
            }
            &-tytul {
                grid-area: tytul;
                //overflow-x: auto;
                //overflow-y: visible;
                //white-space: nowrap;
                overflow-x: scroll;
                overflow-y: hidden;
                white-space: nowrap;
                height: 100%;
                //overflow-wrap: break-word;
                //word-break: break-all;
                display: flex;
                align-items: center;
                padding: 7%;
                padding-bottom: 0;
                & h1 {
                    font-weight: bolder;
                }
            }
            &-info {
                grid-area: info;
                border-right: 1px solid $border-color;
                display: flex;
                flex-direction: column;
                gap: 1rem;
                padding: 5%;
            }
            &-tresc {
                grid-area: tresc;
                padding: 7%;
                & p {
                    font-size: 1rem;
                    overflow-wrap: break-word;
                }
            }
        }
    }

    
</style>