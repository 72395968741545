<template>
    <div class="footer">
        <div class="footer-info" >
            <h3>Informacje</h3>
            <p>GigaKorki.pl to najlepszy w Polsce serwis dla korepetytorów i uczniów.
            Bez problemu znajdziesz tutaj najlepszą pomoc w nauce, np przygotowanie do matury
            lub egzaminu. Najlepsi w Polsce korepetytorzy dodają tutaj swoje ogłoszenia,
            a uczniowie mogą z nich korzystać</p>
        </div>
        <div class="ogloszenia">
            <h3>Ogłoszenia</h3>
            <RouterLink to="/ogloszenia"><p>Ogłoszenia korepetytorów</p></RouterLink>
        </div>
        <div class="footer-help">
            <h3>Pomoc</h3>
            <RouterLink to="/kontakt" target="_blank"><p>Kontakt</p></RouterLink>
            <RouterLink to="/regulamin" target="_blank"><p>Regulamin</p></RouterLink>
        </div>
        <div class="footer-kontakt">
            <h3>Serwis</h3>
            <RouterLink to="/rejestracja"><p>Załóż konto</p></RouterLink>
            <RouterLink to="/opinie" target="_blank"><p>Opinie</p></RouterLink>
            <!-- <RouterLink to="/blog" target="_blank"><p>Blog</p></RouterLink>
            <RouterLink to="/newsletter" target="_blank"><p>Newsletter</p></RouterLink> -->
        </div>
        <div class="footer-prawa" id="footer-prawa">
            <p>Copyright © 2023 - 2023 <RouterLink to="/">GigaKorki.pl</RouterLink>. Wszelkie prawa zastrzeżone</p>
            <p>Nasz Serwis wykorzystuje mechanizm plików cookies. <RouterLink to="/prywatnosc" target="_blank">Polityka prywatności</RouterLink> i <RouterLink to="/polityka" target="_blank">Polityka plików cookies</RouterLink></p>
        </div>
        <div class="footer-design" id="footer-design">
            <p><a href="https://rmdsolutions.eu/">Design by <br>RMD Solutions</a></p>
        </div>
    </div>
</template>

<script>
    export default
    {
        name: 'footerStrony',
        setup()
        {

        },
    }
</script>

<style lang="scss" scoped>
@import '../styleFolder/style.scss';
    *
    {
        font-family: Arial, Helvetica, sans-serif;
        text-decoration: none;
        box-sizing: border-box;
    }
    .footer
    {
        display: grid;
        position: relative;
        grid-template-columns: 30% 30% 30%;
        //grid-template-rows: 20% 10% 10% 10%;
        font-size: 0.9rem;
        column-gap: 3%;
        row-gap: 5vh;
        justify-content: center;
        padding-top: 1%;
        padding-bottom: 1%;
        opacity: 1;
        z-index: 500;
        bottom: 0%;
        left: 0%;
        width: 100vw;
        height: 40%;

        background-color: white;
        color: $font-color;
        border-top: solid 1px $font-color;
        & #footer-prawa
        {
            grid-column: 1 / 4;
        }
        &-design
        {
            grid-column: 1 / 4;
            & p
            {
                text-align: center;
            }
        }
        &-info
        {
            grid-column: 1 / 4;
        }
        & div
        {
            //margin:0;
            text-align: left;
            & h3
            {
                margin-bottom: 2vh;
            }
            & p
            {
                color: $font-color;
            }
            & a
            {
                & p
                {
                    margin-bottom: 2vh;
                    &:hover
                    {
                        color: $main-color;
                        transition: 0.2s ease-out;
                    }
                }

                &:hover
                {
                    color: $main-color;
                    transition: 0.2s ease-out;
                }
            }
        }

    }
    @media only screen and (min-width: 600px)
    {
      .footer
      {
        grid-template-columns: 20% 20% 20%;
        //grid-template-rows: 40% 60%;
        & #footer-prawa
        {
            grid-column: 1 / 4;
        }
        &-design
        {
            grid-column: 1 / 4;
        }
        &-info
        {
            grid-column: 1/4;
        }
      }
    }

    @media only screen and (min-width: 1000px)
    {
        .footer
      {
        grid-template-columns: 20% 10% 10% 10%;
        grid-template-rows: 40% 60%;
        & #footer-prawa
        {
            grid-column: 1 / 4;
        }
        &-design
        {
            grid-column: 4 / 5;
        }
        &-info
        {
            grid-column: 1;
        }
      }
    }
</style>