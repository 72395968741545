<template>
    <stronaGlownaBody/>
</template>

<script>

import stronaGlownaBody from './stronaGlownaFolder/stronaGlownaBody.vue';

export default {
    name: 'stronaGlowna',
    components: {
        stronaGlownaBody,
    },
    setup() {
    // Zmiana tytułu strony
    document.title = 'Najlepsze korepetycje online';
  },
};
</script>

<style lang="scss" scoped>

</style>