<template>
    <router-link :to="{name: 'podglad', params: {id: numer, ogloszenieInfo: $props.ogloszenieInfo.id}}">
        <div class="ad">
            <div class="awatar">
                <img :src="$props.ogloszenieInfo.awatar"/>
            </div>
            <div class="username">
                <h4>{{ $props.ogloszenieInfo.username }}</h4>
                <p v-if="$props.ogloszenieInfo.zweryfikowany">Zweryfikowany</p>
            </div>
            <div class="tytul">
                <a class="niebieski">{{ $props.ogloszenieInfo.przedmiot }}</a>
                <h2 class="wrapuj">{{ $props.ogloszenieInfo.tytul }}</h2>
            </div>
            <div class="tresc ">
                {{ $props.ogloszenieInfo.opis }}
            </div>
            <div class="miejsce">
                <p>Miejsce</p>
                <a>{{ $props.ogloszenieInfo.online ? "online," : '' }}{{ $props.ogloszenieInfo.uUcznia ? " u Ucznia," : '' }}{{ $props.ogloszenieInfo.uKorepetytora ? " u Korepetytora" : '' }}</a>
            </div>
            <div class="cena" :class="{cena2:!$props.ogloszenieInfo.wyroznioneDo}">
                <img v-if="$props.ogloszenieInfo.zweryfikowany" src="../staleRzeczy/logo52.png" id="sunglasy"/>
                {{ $props.ogloszenieInfo.cena }} zł <br>
                <a>za {{ $props.ogloszenieInfo.dlugoscLekcji }} minut</a>
            </div>
        </div>
    </router-link>
</template>
 
<script>
    export default {
        name: 'stronaOgloszeniaBody',
        props: ['numer', 'ogloszenieInfo', 'ogloszenia', 'moje'],
        setup() 
        {

        }
    }
</script>

<style lang="scss" scoped>
    @import '../styleFolder/style.scss';
    *
    {
        color: $font-color;
        text-decoration: none;
        box-sizing: border-box;
    }
    .wrapuj
    {
        overflow: auto;
    }
    .niebieski
    {
        color:$main-color;
    }
    .ad
    {
        width: 100%;
        height: 40vh;
        background-color: transparent;
        display: grid;
        grid-template-columns: 30% 70%;
        grid-template-rows: 40% auto 30% 20% 40%;
        grid-template-areas: 
        "awatar username"
        "tytul tytul"
        "tresc tresc"
        "miejsce miejsce"
        "cena cena";
        border-radius: 2vh;
        margin-top: 5%;
        margin-bottom: 30vh;
        & .awatar
        {
            grid-area: awatar;
            border-top-left-radius: 2vh;
            border-top: 1px solid $main-color;
            border-left: 1px solid $main-color;
            & img 
            {
                height: 100%;
                border-radius: 100vw;
            }
        }
        & div
        {
            padding: 1vh;
        }
        & .username
        {
            grid-area: username;
            border-top: 1px solid $main-color;
            border-right: 1px solid $main-color;
            border-top-right-radius: 2vh;
            text-align: center;
            & p 
            {
                color: $main-color;
                transform: translateY(0.5vh);
                font-weight: bold;
            }
        }
        & .tytul
        {
            grid-area: tytul;
            overflow-wrap: break-word;
            white-space: nowrap;
            border-left: 1px solid $main-color;
            border-right: 1px solid $main-color;
        }
        & .tresc
        {
            grid-area: tresc;
            height: 100%;
            border-left: 1px solid $main-color;
            border-right: 1px solid $main-color;
            overflow-wrap: break-word;
            overflow: hidden;
        }
        & .miejsce
        {
            grid-area: miejsce;
            border-left: 1px solid $main-color;
            border-right: 1px solid $main-color;
            & p
            {
                color: $border-color2;
            }
        }
        & .cena
        {
            grid-area: cena;
            background-color: $main-color;
            border-bottom-right-radius: 2vh;
            border-bottom-left-radius: 2vh;
            color: white;
            display: flex;
            flex-direction: column;
            justify-content: space-evenly;
            align-items: center;
            font-size: 2.5rem;
            font-weight: bold;
            border: 1px solid $main-color;
            & a
            {
                color: white;
                font-size: 1rem;
            }
            & #sunglasy
            {
                height: 8vh;
            }
        }
        & .cena2
        {
            background-color: white;
            color: $main-color;
            & a
            {
                color: $main-color;
            }
        }
    }
    
    @media only screen and (min-width: 600px) 
    {
        .ad
        {
            width: 100%;
            height: 25vh;
            grid-template-columns: 20% 60% 20%;
            grid-template-rows: auto 45% 35%;
            grid-template-areas: 
            "awatar tytul cena"
            "awatar tresc cena"
            "username miejsce cena";
            margin-bottom: 5vh;
            & .awatar
            {
                grid-area: awatar;
                border-radius: 0;
                border-top-left-radius: 2vh;
                
                border: none;
                border-top: 1px solid $main-color;
                border-left: 1px solid $main-color;
                & img 
                {
                    width: 15vw;
                    height: 15vw;
                    border-radius: 100vw;
                }
            }
            & div
            {
                padding: 1vh;
            }
            & .username
            {
                grid-area: username;
                border-radius: 0;
                border: none;
                border-bottom-left-radius: 2vh;
                border-bottom: 1px solid $main-color;
                border-left: 1px solid $main-color;
                text-align: center;
                & p 
                {
                    color: $main-color;
                    transform: translateY(0.5vh);
                    font-weight: bold;
                }
            }
            & .tytul
            {
                grid-area: tytul;
                border-radius: 0;
                border: none;
                border-top: 1px solid $main-color;
            }
            & .tresc
            {
                grid-area: tresc;
                border-radius: 0;
                border: none;
                overflow: hidden;
                height: 100%;
            }
            & .miejsce
            {
                grid-area: miejsce;
                border-radius: 0;
                border: none;
                border-bottom: 1px solid $main-color;
                & p
                {
                    color: $border-color2;
                }
            }
            & .cena
            {
                grid-area: cena;
                border-radius: 0;
                background-color: $main-color;
                border-top-right-radius: 2vh;
                border-bottom-right-radius: 2vh;
                color: white;
                display: flex;
                flex-direction: column;
                justify-content: space-evenly;
                align-items: center;
                font-size: 6vh;
                font-weight: bold;
                border: 1px solid $main-color;
                & #sunglasy
                {
                    width: 15vw;
                    height: 6vw;
                }
            }
            & .cena2
            {
                background-color: white;
                color: $main-color;
            }
        }
    }

    @media only screen and (min-width: 1000px) 
    {
        .ad
        {
            width: 100%;
            height: 25%;
            background-color: transparent;
            display: grid;
            grid-template-columns: 15% 65% 20%;
            grid-template-rows: auto 45% 35%;
            grid-template-areas: 
            "awatar tytul cena"
            "awatar tresc cena"
            "username miejsce cena";
            border-radius: 2vh;
            margin-top: 2%;
            margin-bottom: 8%;
            & .awatar
            {
                grid-area: awatar;
                border-radius: 0;
                border-top-left-radius: 2vh;
                
                border: none;
                border-top: 1px solid $main-color;
                border-left: 1px solid $main-color;
                & img 
                {
                    width: 8vw;
                    height: 8vw;
                    border-radius: 100vw;
                }
            }
            & div
            {
                padding: 1vh;
            }
            & .username
            {
                grid-area: username;
                border-radius: 0;
                border: none;
                border-bottom-left-radius: 2vh;
                border-bottom: 1px solid $main-color;
                border-left: 1px solid $main-color;
                text-align: center;
                & p 
                {
                    color: $main-color;
                    transform: translateY(0.5vh);
                    font-weight: bold;
                }
            }
            & .tytul
            {
                grid-area: tytul;
                border-radius: 0;
                border: none;
                border-top: 1px solid $main-color;
            }
            & .tresc
            {
                grid-area: tresc;
                border-radius: 0;
                border: none;
                
                height: 5vh;
            }
            & .miejsce
            {
                grid-area: miejsce;
                border-radius: 0;
                border: none;
                border-bottom: 1px solid $main-color;
                & p
                {
                    color: $border-color2;
                }
            }
            & .cena
            {
                grid-area: cena;
                border-radius: 0;
                background-color: $main-color;
                border-top-right-radius: 2vh;
                border-bottom-right-radius: 2vh;
                color: white;
                display: flex;
                flex-direction: column;
                justify-content: space-evenly;
                align-items: center;
                font-size: 3rem;
                font-weight: bold;
                border: 1px solid $main-color;
                & #sunglasy
                {
                    width: 8vw;
                    height: 3vw;
                }
            }
            & .cena2
            {
                background-color: white;
                color: $main-color;
            }
        }
    }
</style>