<template>
    <div class="container">
        <div class="main">
            <h1>Polityka prywatności</h1>
            <h2>§1. Wstęp</h2>
            <p>Niniejsza Polityka prywatności opisuje zasady przetwarzania danych osobowych przez stronę internetową GigaKorki.pl. Dbamy o ochronę prywatności naszych użytkowników i dbamy o bezpieczeństwo ich danych. Niniejsza Polityka ma na celu zapewnienie jasnych informacji dotyczących sposobu gromadzenia, przetwarzania i wykorzystania danych osobowych przez naszą stronę.</p>
            <h2>§2. Administrator danych osobowych</h2>
            <p>Administratorem danych osobowych zbieranych za pośrednictwem strony GigaKorki.pl jest Rafał Mironko, właściciel strony GigaKorki.pl. Kontakt z administratorem jest możliwy za pośrednictwem zakładki Kontakt na naszej stronie.</p>
            <h2>§3. Rodzaje zbieranych danych osobowych</h2>
            <p>W trakcie korzystania z naszej strony, możemy zbierać następujące dane osobowe od użytkowników:</p>
            <ol>
                <li>Adres e-mail: podawany podczas rejestracji konta na stronie.</li>
                <li>Dane związane z publikacją ogłoszeń: takie jak dane kontaktowe, opis oferty, zdjęcia, które użytkownicy zamieszczają przy dodawaniu ogłoszeń na stronie.</li>
                <li>Dane związane z korzystaniem ze strony: takie jak adres IP, informacje o używanej przeglądarce, czas i data korzystania z serwisu, odwiedzone strony oraz aktywność na stronie.</li>
            </ol>
            <h2>§4. Cele przetwarzania danych osobowych</h2>
            <p>Przetwarzamy dane osobowe użytkowników w następujących celach:</p>
            <ol>
                <li>Zapewnienie pełnej funkcjonalności serwisu, w tym umożliwienie logowania do konta, dodawanie ogłoszeń oraz korzystanie z panelu kontaktowego.</li>
                <li>Obsługa zapytań, sugestii i uwag zgłaszanych przez użytkowników za pośrednictwem formularza kontaktowego.</li>
                <li>Personalizacja doświadczenia użytkownika, w tym dostarczanie spersonalizowanych treści i reklam na podstawie aktywności na stronie.</li>
                <li>Analiza ruchu na stronie w celu doskonalenia jej funkcjonalności i dostosowania do potrzeb użytkowników.</li>
                <li>Przeprowadzanie badań i analiz w celu ulepszania naszych usług.</li>
            </ol>
            <h2>§5. Udostępnianie danych osobowych</h2>
            <p>Dbamy o ochronę prywatności naszych użytkowników i nie sprzedajemy ani nie przekazujemy ich danych osobowych osobom trzecim bez wyraźnej zgody użytkownika, chyba że wymaga tego obowiązujące prawo.</p>
            <h2>§6. Okres przechowywania danych osobowych</h2>
            <p>Dane osobowe użytkowników przechowujemy przez okres niezbędny do osiągnięcia celów, dla których zostały zebrane, chyba że obowiązujące przepisy prawa wymagają dłuższego okresu przechowywania.</p>
            <h2>§7. Bezpieczeństwo danych osobowych</h2>
            <p>Stosujemy odpowiednie środki techniczne i organizacyjne w celu ochrony danych osobowych użytkowników przed nieuprawnionym dostępem, utratą, uszkodzeniem czy ujawnieniem.</p>
            <h2>§8. Prawa użytkowników</h2>
            <p>Użytkownicy mają prawo dostępu do swoich danych osobowych, ich sprostowania, usunięcia, ograniczenia przetwarzania oraz przenoszenia. Mają także prawo wycofania zgody na przetwarzanie danych osobowych w dowolnym momencie.</p>
            <h2>§9. Zgoda na przetwarzanie danych osobowych</h2>
            <p>Korzystając z naszej strony, użytkownik wyraża zgodę na przetwarzanie swoich danych osobowych zgodnie z niniejszą Polityką prywatności.</p>
            <h2>§10. Zmiany w Polityce prywatności</h2>
            <p>Polityka prywatności może być okresowo aktualizowana w celu odzwierciedlenia zmian w prawie lub naszych praktykach związanych z przetwarzaniem danych osobowych. W przypadku wprowadzenia istotnych zmian, użytkownicy zostaną poinformowani poprzez odpowiednie komunikaty na naszej stronie.</p>
        </div>
    </div>
</template>

<script>

</script>

<style lang="scss" scoped>
    .container
    {
        margin-top: 10vh;
        display: flex;
        justify-content: center;
        min-height: 80vh;
    }
    .main
    {
        margin-top: 10vh;
        margin-bottom: 10vh;
        width: 60vw;
        & ul li, ol li
        {
            line-height: 2rem;
        }
        & p 
        {
            line-height: 2rem;
        }
        & h2 
        {
            font-size: 2rem;
            line-height: 3rem;
        }
        & h1
        {
            text-align: center;
            font-size: 3rem;
            line-height: 4rem;
        }
    }
    
    @media only screen and (min-width: 600px) 
    {

    }

    @media only screen and (min-width: 1000px) 
    {

    }
</style>