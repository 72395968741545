import { createRouter, createWebHistory } from 'vue-router'
import stronaRejestracji from '../components/stronaRejestracji'
import stronaLogowania from '../components/stronaLogowania'
import stronaGlowna from '../components/stronaGlowna'
import stronaKonta from '../components/stronaKonta'
import stronaOgloszenia from '../components/stronaOgloszenia'
import stronaPodgladu from '../components/stronaPodgladu'
import stronaNotFound from '../components/stronaNotFound.vue'
import stronaKontakt from '../components/stronaPozostale/stronaKontakt'
import stronaPodgladuWiadomosc from '../components/stronaPodgladuFolder/stronaPodgladuWiadomosc'
import stronaPrywatnosc from '../components/stronaPozostale/stronaPrywatnosc'
import stronaBlog from '../components/stronaPozostale/stronaBlog'
import stronaNewsletter from '../components/stronaPozostale/stronaNewsletter'
import stronaOpinie from '../components/stronaPozostale/stronaOpinie'
import stronaPolityka from '../components/stronaPozostale/stronaPolityka'
import stronaRegulamin from '../components/stronaPozostale/stronaRegulamin'
import pobierzLink from '../components/stronaKontaFolder/pobierzLink'
import stronaWiadomosci from '../components/stronaKontaFolder/stronaWiadomosci'
import stronaKontaUstawienia from '../components/stronaKontaFolder/stronaKontaUstawienia'
import stronaKontaDodajOgloszenie from '../components/stronaKontaFolder/stronaKontaDodajOgloszenie'
import stronaKontaHistoriaZamowien from '../components/stronaKontaFolder/stronaKontaHistoriaZamowien'
import stronaKontaMojeOgloszenia from '../components/stronaKontaFolder/stronaKontaMojeOgloszenia'
import stronaKontaWyloguj from '../components/stronaKontaFolder/stronaKontaWyloguj'
import stronaKontaWyroznijOgloszenie from '../components/stronaKontaFolder/stronaKontaWyroznijOgloszenie'


const routes = [
  {
    path: '/',
    name: 'stronaGlowna',
    component: stronaGlowna,
  },
  {
    path: '/rejestracja',
    name: 'rejestracja',
    component: stronaRejestracji,
  },
  {
    path: '/logowanie',
    name: 'logowanie',
    component: stronaLogowania,
  },
  {
    path: '/konto',
    name: 'konto',
    component: stronaKonta,
    children: [
      {
        path:'ustawienia',
        component: stronaKontaUstawienia,
      },
      {
        path:'wiadomosci',
        name:'wiadomosci',
        component: stronaWiadomosci,
      },
      {
        path:'link',
        name:'link',
        component: pobierzLink,
      },
      {
        path:'dodajOgloszenie/:offerId?',
        name:'dodajOgloszenie',
        component: stronaKontaDodajOgloszenie,
        props: true,
      },
      {
        path:'historiaZamowien',
        component: stronaKontaHistoriaZamowien,
      },
      {
        path:'',
        name: 'pusty',
        component: stronaKontaMojeOgloszenia,
      },
      {
        path:'wyloguj',
        component: stronaKontaWyloguj,
      },
      {
        path:'wyroznijOgloszenie',
        name:'wyroznijOgloszenie',
        component: stronaKontaWyroznijOgloszenie,
        props: true,
      },
    ]
  },
  {
    path: '/ogloszenia',
    name: 'ogloszenia',
    component: stronaOgloszenia,
    props: true,
  },
  {
    path: '/podglad/:id/:ogloszenieInfo',
    name: 'podglad',
    component: stronaPodgladu,
    props: true,
  },
  {
    path: '/wiadomosc/:id/:ogloszenieInfo',
    name: 'wiadomosc',
    component: stronaPodgladuWiadomosc,
    props: true,
  },
  {
    path:'/kontakt',
    name:'kontakt',
    component: stronaKontakt,
  },
  {
    path:'/polityka',
    name:'polityka',
    component: stronaPolityka,
  },
  {
    path:'/prywatnosc',
    name: 'prywatnosc',
    component: stronaPrywatnosc,
  },
  {
    path:'/regulamin',
    name:'regulamin',
    component: stronaRegulamin,
  },
  {
    path:'/opinie',
    name:'opinie',
    component: stronaOpinie,
  },
  {
    path:'/newsletter',
    name:'newsletter',
    component: stronaNewsletter,
  },
  {
    path:'/blog',
    name:'blog',
    component: stronaBlog,
  },
  {
    path: '/:catchAll(.*)',
    name: 'notFound',
    component: stronaNotFound
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) 
  {
    if (savedPosition) 
    {
      return savedPosition
    } 
    
    else 
    {
      return { top: 0 }
    }
  },
})

export default router
