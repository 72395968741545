<template>
    <stronaOgloszeniaBody/>
  </template>
  
  <script>
      import stronaOgloszeniaBody from './stronaOgloszeniaFolder/stronaOgloszeniaBody.vue'; 
      export default 
      {
        name: 'stronaOgloszenia',
        components: 
        {
          stronaOgloszeniaBody,
  
        },
        setup()
        {
            document.title = 'Wyszukiwarka ogłoszeń';
        }
      }
  </script>
  
  <style lang="scss" scoped>
  
  </style>
  
  