<template>
    <div class="main-prawy">
        <a v-show="!czyVerified" id="komunikat"><a>Status Zweryfikowanego Korepetytora umożliwia Ci zdobycie nawet 3x więcej odpowiedzi na każde z Twoich ogłoszeń.</a> <a id="verify">Zweryfikuj się teraz</a></a>
        <edytujOgloszenie v-for="(ogloszenie,index) in dane" :key="ogloszenie.id" :ogloszenieInfo="ogloszenie" :numerListy="index"/>
    </div>
</template>

<script>
    import edytujOgloszenie from './edytujOgloszenie.vue';
    import {ref, onMounted, inject} from "vue";
    import axios from "axios";
import router from '@/router';
    export default
    {
        name: 'stronaKontaMojeOgloszenia',
        components:
        {
            edytujOgloszenie,
        },
        setup()
        {
            const endpoint = inject('g_endpoint');
            var ogloszenia = [];
            var dane = ref([]);
            var czyVerified = ref('');
            function getCookie(cname)
            {
            let name = cname + "=";
            let ca = document.cookie.split(';');
            for(let i = 0; i < ca.length; i++) {
                let c = ca[i];
                while (c.charAt(0) == ' ') {
                c = c.substring(1);
                }
                if (c.indexOf(name) == 0) {
                return c.substring(name.length, c.length);
                }
            }
            return "";
            }

            async function checkVerification()
            {
                let response = await axios.get(`${endpoint}api/users/${getCookie('userId')}/`);
                let verified = response.data.verified_to;
                if(verified == null)
                {
                    czyVerified.value=null;
                    return false;
                }
                verified = new Date(verified).getTime();
                const currentDate = new Date();
                let dzisiaj = new Date(currentDate.getTime());

                let roznica = verified - dzisiaj;

                if(roznica > 0) czyVerified.value = roznica;
                else czyVerified.value = null;
                //if(true) return true;
                //else return false;
            }



            async function verify()
            {
                router.push('/konto/wyroznijOgloszenie')
            }
            const fetchData = async () =>
            {
                try
                {
                    const response2 = await axios.get(`${endpoint}api/users/`);
                    const response = await axios.get(`${endpoint}api/offers/`);

                    ogloszenia = response.data;


                    ogloszenia.forEach(element =>
                    {
                        const index = response2.data.findIndex(user => user.id === element.user);
                        let user = response2.data[index];

                        if(user.id==getCookie('userId'))
                        {
                            const dana =
                            {
                                id: element.id,
                                awatar: require('../stronaOgloszeniaFolder/Obraz1.jpg'),
                                username: user.name,
                                tytul: element.title,
                                przedmiot: element.subject,
                                cena: element.hour_price,
                                dlugoscLekcji: element.lesson_time,
                                dostepnosc: user.when_he_can_teach,
                                opis: element.description,
                                wyroznioneDo: element.outstanding_to,
                                zweryfikowany: user.verified_to,
                                wyswietlenia: element.number_of_views,
                            };
                            dane.value.push(dana);
                        }
                    });
                    document.getElementById('verify').addEventListener("click", verify);
                    //console.log(czyVerified.value);
                    checkVerification();
                }
                catch (error)
                {
                    console.error('Błąd podczas pobierania danych z API:', error);
                }
            };

            onMounted(() =>
            {
                fetchData();


            });
            return { dane, czyVerified };
        }
    }
</script>

<style lang="scss" scoped>
    @import '../styleFolder/style.scss';
    #komunikat
    {
        & a
        {
        color: white;

        }
        & #verify
        {
            cursor: pointer;
            padding-left: 1%;
        }
        box-sizing: border-box;
        background-color: $warning-color;
        border-radius: 2vh;
        padding: 2%;
        padding-left: 4%;
        padding-right: 4%;
        line-height: 1.5rem;
        margin-bottom: 2%;
        width: 100%;
        display: flex;
        flex-direction: column;
        row-gap: 2vh;
        justify-content: space-between;
        align-items: center;
    }
    .main-prawy
    {
        display: flex;
        gap: 2%;
        flex-direction: column;
    }
    @media only screen and (min-width: 600px)
    {

    }

    @media only screen and (min-width: 1000px)
    {
        #komunikat
        {
            padding: 1%;
            padding-left: 2%;
            padding-right: 2%;
            flex-direction: row;
        }
    }
</style>