<template>
  <banerStrony/>
  <router-view/>
  <footerStrony/>
</template>

<script>
import banerStrony from './components/staleRzeczy/banerStrony.vue';
import footerStrony from './components/staleRzeczy/footerStrony.vue';

export default {
  name: 'App',
  components: 
  {
    banerStrony,
    footerStrony,
}
}
</script>

<style lang="scss">
  @import './components/styleFolder/style.scss';
  *
  {
    margin: 0;
    font-family: helvetica;
    color: $font-color;
  }
</style>
