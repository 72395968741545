<template>
    <div class="containerI">
        <div class="main">
            <h2>Konto</h2>
            <ul>
                <li><router-link class="main-link" to="/konto/" id="mojeOgloszeniaLink">Moje Ogłoszenia</router-link></li>
                <li><router-link class="main-link" to="/konto/wiadomosci" id="wiadomosciLink">Wiadomości</router-link></li>
                <li><router-link class="main-link" to="/konto/dodajOgloszenie" exact-path id="dodajOgloszenieLink">Dodaj Ogłoszenie</router-link></li>
                <li><router-link class="main-link" to="/konto/wyroznijOgloszenie" exact-path id="zwiekszZasiegLink">Zwiększ Zasięg</router-link></li>
                <li><router-link class="main-link" to="/konto/ustawienia" id="ustawieniaLink">Ustawienia</router-link></li>
                <li><router-link class="main-link" to="/konto/historiaZamowien" id="historiaZamowienLink">Historia Zamówień</router-link></li>
                <li><router-link class="main-link" to="/konto/link" id="polecNasLink" v-if="false">Poleć nas</router-link></li>
                <li @click="wyloguj"><a>Wyloguj</a></li>
            </ul>
        </div>
        <div class="opcje">
            <router-view class="opcje-view"></router-view>
        </div>
    </div>
</template>

<script>
    import { useStore } from 'vuex'; // Zaimportuj useStore
    import axios from 'axios';
    import router from '@/router';
    import { inject } from 'vue';

    export default {
    name: 'stronaKontaBody',
    setup() {
        const store = useStore(); // Uzyskaj dostęp do store Vuex
        const endpoint = inject('g_endpoint');
        async function wyloguj() {
        try {
            
            await store.dispatch('logout'); // Użyj store do wywołania akcji 'logout'

            await axios.get(`${endpoint}logout/`);

            document.cookie = `tokenCSRF=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
            document.cookie = `tokenDRF=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
            document.cookie = `userId=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
            document.cookie = `pokazWarning=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;

            localStorage.clear();
            sessionStorage.clear();

            await router.push('/');
        } catch (error) {
            console.error(error);
        }
        }

        return { wyloguj };
    },
    };

</script>

<style lang="scss" scoped>
    @import '../styleFolder/style.scss';

    .containerI 
    {
        display: grid;
        grid-template-columns: 100vw;
        grid-template-rows: auto auto;
        gap: 5vh;
        min-height: 70vh;
    }

    .main 
    {
        border: none;
        border-bottom: 1px solid $border-color;
        text-align: center;
        ul 
        {
            padding: 0;
            list-style-type: none;
            padding-top: 2vh;

            li 
            {
                line-height: 3rem;

                a 
                {
                    color: $font-color;
                    text-decoration: none;
                    transition: 0.2s ease-out;
                    cursor: pointer;

                    &:hover 
                    {
                        color: $main-color;
                    }
                }
            }
        }
    }

    .opcje 
    {
        margin-bottom: 15vh;
    }

    a.router-link-exact-active 
    {
        color: $main-color !important;
    }

    @media only screen and (min-width: 600px) 
    {
        .containerI 
        {
            grid-template-columns: 25vw 60vw;
            gap: 2vw;
            justify-content: center;
        }

        .main 
        {
            border: none;
            border-right: 1px solid $border-color;

            ul 
            {
                text-align: left;
            }
        }
    }

    @media only screen and (min-width: 1000px) 
    {
        .containerI 
        {
            grid-template-columns: 15vw 60vw;
        }
    }
</style>