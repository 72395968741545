<template>
    <div class="prawy">
        <div class="menu">
            <div @click="numer=1" :class="{ active: numer === 1 }" id="div1">
                Wyróżnij ogłoszenie
            </div>
            <div @click="numer=2" :class="{ active: numer === 2 }" id="div2">
                Zweryfikuj konto
            </div>
        </div>
       <div class="srodek" v-if="numer==1">
            <select class="wyr-select" v-model="wybrane" :class="{ greyC: wybrane=='' }">
                <option disable selected hidden value="">Wybierz ogłoszenie do wyróżnienia</option>
                <option v-for="element in ogloszenia" :key="element" :value="element.id"> {{ element.title }}</option>
            </select>
            <select class="wyr-select" v-model="cena" :class="{ greyC: cena=='0,0' }">
                <option disable selected hidden value="0,0">Wybierz czas wyróżnienia ogłoszenia</option>
                <option value="7,1">1 miesiąc</option>
                <option value="12,2">2 miesiące</option>
                <option value="18,3">3 miesiące</option>
                <option value="33,6">6 miesięcy</option>
                <option value="60,12">12 miesięcy</option>
            </select>
       </div>
       <div class="srodek" v-else>
            <select class="wer-select" v-model="cena" :class="{ greyC: cena=='0,0' }">
                <option disable selected hidden value="0,0">Wybierz, na ile czasu chcesz zweryfikować konto</option>
                <option value="10,1">1 miesiąc</option>
                <option value="18,2">2 miesiące</option>
                <option value="27,3">3 miesiące</option>
                <option value="50,6">6 miesięcy</option>
                <option value="89,12">12 miesięcy</option>
            </select>
       </div>
       <div class="podsumowanie">
            <div class="cena">{{ koszt }} zł</div>
            <button @click="numer==1 ? setWyroznienie() : setWeryfikacja()" type="button">Przedłuż</button>
       </div>
       
    </div>
    <div class="copy">
        <div class="srodek">
        <h1>Dlaczego warto zweryfikować konto i wyróżnić swoje ogłoszenia?</h1>
        </div>
        <p>Mało osób odpowiada na Twoje ogłoszenia?</p><br>
        <p>Chciałbyś mieć ponad 5x więcej wyświetleń i wiadomości niż teraz?</p><br>
        <p>Zainwestuj w wyróżnienie ogłoszenia lub weryfikację konta!</p><br>
        <p>Już od 7 złotych za miesiąc możesz sprawić, że Twoje ogłoszenia będą wyświetlały się na samej górze listy.</p><br>
        <p>Na GigaKorkach ogłoszenia wyświetlają się w następującej kolejności: Wyróżnione i Zweryfikowane, Wyróżnione, Zweryfikowane, pozostałe.</p><br>
        <p>Wyróżnienie jest najtańsze, działa na jedno ogłoszenie i pozwala Ci łatwo wyświetlić je wysoko na liście. </p><br>
        <p>Jest to idealna opcja, jeśli chcesz udzielać niewielką ilość korepetycji, np. tylko żeby dorobić sobie kilkaset złotych w miesiącu.</p><br>
        <p>Jeśli jesteś profesjonalnym korepetytorem lub po prostu zamierzasz udzielać ich z kilku przedmiotów, warto rozważyć Weryfikację konta. </p><br>
        <p>Za 10 złotych miesięcznie możesz sprawić, że KAŻDE z Twoich ogłoszeń będzie wyświetlało się wyżej.</p><br>
        <p>Do tego użytkownicy będą bardziej Ci ufali i chętniej zakupią korepetycje u Ciebie.</p><br>
        <p>Dla osób chcących zarobić najwięcej pieniędzy i mieć największą ilość klientów, polecamy połączenie Weryfikacja + Wyróżnienie. Dzięki temu Twoje ogłoszenia będą wyświetlały się na samej górze listy, a osoby przeglądające oferty od razu się nimi zainteresują.</p><br>
        <p>Takie połączenie może przynieść Ci nawet 5x więcej klientów na każdym ogłoszeniu, a co za tym idzie, 5x więcej pieniędzy!</p><br>
        <p>Nie czekaj. Zainwestuj w siebie i Zweryfikuj się już teraz.</p><br>
    </div>
</template>

<script>
import router from '@/router';
import { useRoute } from 'vue-router';
import axios from 'axios';
import { computed, onMounted, ref, watch, inject } from 'vue';

    export default
    {
        name: 'stronaKontaWyroznijOgloszenie',
        props:
        {
            nrOgloszenia:{

            }
        },
        setup()
        {
            const endpoint = inject('g_endpoint');
            const route = useRoute();
            var numer = ref(1);
            var cena = ref('0,0');
            let parts = computed(() => {return cena.value.split(',')});
            let koszt = computed(() => {return parts.value[0]});
            let czas = computed(() => {return parts.value[1]});
            var wybrane = ref('');
            var email = ref('');
            //var staraDataWyr = '';
            //var staraDataWer = '';
            var ogloszenia = ref([]);
            watch(numer, function(){
                wybrane.value='';
                cena.value='0,0';
            })
            function getCookie(cname) 
            {
                let name = cname + "=";
                let ca = document.cookie.split(';');
                for(let i = 0; i < ca.length; i++) {
                    let c = ca[i];
                    while (c.charAt(0) == ' ') {
                    c = c.substring(1);
                    }
                    if (c.indexOf(name) == 0) {
                    return c.substring(name.length, c.length);
                    }
                }
                return "";
            }
            async function fetchData()
            {
                const response = await axios.get(`${endpoint}api/offers/`)
                let oferta = response.data;
                const response2 = await axios.get(`${endpoint}api/users/${getCookie('userId')}/`)
                email.value = response2.data.email;
                oferta.forEach(element => {
                    if(element.user==getCookie('userId')) ogloszenia.value.push(element);
                });
                
                let nrOgloszenia = route.query.nrOgloszenia
                if(nrOgloszenia) wybrane.value = nrOgloszenia;
            }

            async function setWyroznienie()
            {
                const currentDate = new Date();
                const futureDate = new Date(currentDate.getTime() + (czas.value * 30 * 24 * 60 * 60 * 1000));
                const year = futureDate.getFullYear();
                const month = (futureDate.getMonth() + 1).toString().padStart(2, '0');
                const day = futureDate.getDate().toString().padStart(2, '0');
                const docelowa = `${year}-${month}-${day}`;

                var cos = {
                    user: getCookie('userId'),
                    outstanding_to: docelowa,
                }
                let cosG = JSON.stringify(cos);
                await axios
                .put(`${endpoint}api/offers/${wybrane.value}/`, cosG, {
                withCredentials: true,
                headers: {
                    'X-CSRFToken': `${getCookie('tokenCSRF')}`,
                    'Authorization': `Token ${getCookie('tokenDRF')}`,
                    'Content-Type': "application/json"
                }
                })
                .then((response) => 
                {
                    console.log(response);
                    router.push('/konto');
                })
                .catch(error => 
                {
                    console.log(error);
                })
            }

            async function setWeryfikacja()
            {
                const currentDate = new Date();
                const futureDate = new Date(currentDate.getTime() + (czas.value * 30 * 24 * 60 * 60 * 1000));
                const year = futureDate.getFullYear();
                const month = (futureDate.getMonth() + 1).toString().padStart(2, '0');
                const day = futureDate.getDate().toString().padStart(2, '0');
                const docelowa = `${year}-${month}-${day}`;

                var cos = {
                    email: email.value,
                    verified_to: docelowa,
                }
                let cosG = JSON.stringify(cos);
                await axios
                .put(`${endpoint}api/users/${getCookie('userId')}/`, cosG, {
                withCredentials: true,
                headers: {
                    'X-CSRFToken': `${getCookie('tokenCSRF')}`,
                    'Authorization': `Token ${getCookie('tokenDRF')}`,
                    'Content-Type': "application/json"
                }
                })
                .then((response) => 
                {
                    console.log(response);
                    router.push('/konto');
                })
                .catch(error => 
                {
                    console.log(error);
                })
            }

            onMounted(()=>{
                fetchData();
            })

            return {numer, cena, ogloszenia, wybrane, koszt, czas, setWyroznienie, setWeryfikacja}
        }
    }
</script>

<style lang="scss" scoped>
    @import '../styleFolder/style.scss';
    *
    {
        box-sizing: border-box;
    }
    .greyC
    {
        color: $border-color2 !important;
        opacity: 1;
    }
    .prawy
    {
        height: 40vh;
        border: 1px solid $border-color;
        border-radius: 2vh;
        box-shadow: 0.5vh 0.5vh 1vh 1vh $border-color;
    }
    .copy
    {
        width: 80%;
        margin-left: 10%;
        margin-top: 5%;
        min-height: 40vh;
        display: flex;
        gap: 1rem;
        flex-direction: column;
        justify-content: space-evenly;
        & .srodek
        {
        width: 100%;
        text-align: center;
        & input
        {
            width: 100%;
        }
        }
    }
    .menu
    {
        width: 100%;
        height: 10vh;
        background-color: $border-color;
        border-top-left-radius: 2vh;
        border-top-right-radius: 2vh;
        display: flex;
        & #div1
        {
            border-top-left-radius: 2vh;
        }
        & #div2
        {
            border-top-right-radius: 2vh;
        }
        & div 
        {
            width: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            transition: 0.2s ease-out;
            &:hover
            {
                background-color: $main-color;
                color: white;
            }
        }
        & .active
        {
            background-color: $main-color;
            color: white;
        }
    }
    .srodek
    {
        display: flex;
        flex-direction: column;
        height: calc(100% - 20vh);
        padding: 1%;
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        & div 
        {
            width: 50%;
        }
        & input, select {
            margin-top: 2vh;
            width: 90%;
            height: 30%;
            background-color: white;
            border-radius: 100vw;
            border: 1px solid $border-color;
            padding-left: 1%;
            font-size: 1rem;
            color: $font-color;
            outline: none;
        }
    }
    .podsumowanie
    {
        width: 100%;
        height: 10vh;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        & div
        {
            width: 20%;
            display: flex;
            justify-content: center;
            align-items: center;
        }
        & button 
        {
            width: 20%;
            height: 50%;
            color: white;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: $main-color;
            border-radius: 100vw;
            border: 1px solid white;
            cursor: pointer;
            font-size: 1rem;
            font-weight: bold;
            transition: 0.3s;
            text-decoration: none;
            &:hover {
                color: $main-color;
                background-color: white;
                border: 1px solid $main-color;
            }
        }
    }
    
    @media only screen and (min-width: 600px) 
    {

    }

    @media only screen and (min-width: 1000px) 
    {
        .srodek
        {
            flex-direction: row;
            & input, select 
            {
                width: 45%;
            }
        }
    }
</style>