<template>
    <div class="container">
        <div class="lewy">
            <h1>Filtrowanie</h1>
            <div class="rzeczy">
                <div class="dane">
                    <label for="mode">Tryb</label><br>
                    <select id="mode" name="mode" v-model="mode" :class="{ greyC: mode=='' }">
                        <option value="" disabled selected hidden>Szukasz czy udzielasz korepetycji?</option>
                        <option value="student">Szukam korepetytora</option>
                        <option value="tutor">Udzielę korepetycji</option>
                    </select>
                </div>
                <div class="dane">
                    <label for="miasto">Miasto</label><br>
                    <select id="miasto" name="miasto" v-model="miasto" :class="{ greyC: miasto=='' }">
                        <option value="" hidden>Wybierz miasto</option>
                        <option v-for="(element, index) in miasta" :key="index" :value="index+1">{{ element }}</option>
                    </select>
                </div>
                <div class="dane">
                    <label for="przedmiot">Przedmiot</label><br>
                    <select id="przedmiot" name="przedmiot" v-model="subject" :class="{ greyC: subject=='' }">
                        <option  value="" hidden>Wybierz przedmiot</option>
                        <option v-for="(element, index) in przedmioty" :key="element" :value="przedmiotyNr[index]">{{ element }}</option>
                    </select>
                </div>
                <div class="dane">
                    <label for="miejsce">Miejsce pracy</label><br>
                    <select id="miejsce" name="miejsce" v-model="miejsce" :class="{ greyC: miejsce=='' }">
                        <option value="" hidden>Wybierz miejsce pracy</option>
                        <!-- <option value="dowolnie">Dowolnie</option> -->
                        <option value="uKorepetytora">U korepetytora</option>
                        <option value="uUcznia">U ucznia</option>
                        <option value="online">Online</option>
                    </select>
                </div>
                <div class="dane">
                    <label for="od">Cena za lekcję</label><br>
                    <input type="text" id="od" name="od" placeholder="Od" v-model="cenaMin"/>
                    <input type="text" id="do" name="do" placeholder="Do" v-model="cenaMax"/>
                </div>
                <div class="dane">
                    <label for="zakres">Zakres materiału</label><br>
                    <select id="zakres" name="zakres" v-model="zakres" :class="{ greyC: zakres=='' }">
                        <option value="" hidden>Wybierz poziom</option>
                        <option value="1">Przedszkole</option>
                        <option value="2">Szkoła podstawowa</option>
                        <option value="3">Egzamin ósmoklasisty</option>
                        <option value="4">Szkoła średnia</option>
                        <option value="5">Matura</option>
                        <option value="6">Studia</option>
                        <option value="7">Inne</option>
                    </select>
                </div>
                <div class="dane">
                    <a @click="filtruj"><button type="submit" id="submit" name="submit">Filtruj</button></a>
                </div>
            </div>
        </div>
        <div class="main">
            <h1>Ogłoszenia</h1>
            <h4>Wyniki filtrowania</h4>
            <div>
                <stronaOgloszeniaAds v-for="(ogloszenie,index) in dane.slice(poczatek,koniec)" :key="ogloszenie.id" :ogloszenieInfo="ogloszenie" :numer="dane[index].id" :moje="false"/>
            </div>
            <div class="nawigacja">
                <ul>
                    <li class="nav-list" v-for="index in parseInt(Math.ceil(dane.length/10))" :key="index" :id="`nav-list${index}`" :class="{klikniety: poczatek/10 == index-1}"> {{ index }}</li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
    import stronaOgloszeniaAds from './stronaOgloszeniaAds.vue';
    import { ref, onMounted, computed, reactive, toRefs, inject} from "vue";
    import { useRoute } from 'vue-router';
    import axios from "axios";
    import router from '@/router';

    var miasta = ref([]);
    var przedmioty = ref([]);
    var przedmiotyNr = ref([]);

    var numer = ref(1);
    var tab = [1,2,3,4,5,6,7,8,9,10];
    var poczatek = ref(0);



    var koniec = computed(()=> {
        return poczatek.value+10;
    })

    export default
    {
        name: 'stronaOgloszeniaBody',
        components:
        {
            stronaOgloszeniaAds,

        },
        props:
        {
            query:
            {

            }
        },
        setup()
        {
            const endpoint = inject('g_endpoint');
            var ogloszenia = [];
            var dane = ref([]);
            var filtry = computed(() => {
                let filtr = '?';
                Object.keys(rodzajeFiltrow).forEach(key => {
                    filtr += `${key}=${rodzajeFiltrow[key]}&`;
                });
                return filtr;
            });
            var rodzajeFiltrow = reactive({
                mode: '',
                miasto: '',
                subject: '',
                miejsce: '',
                cenaMin: '',
                cenaMax: '',
                zakres: '',
            })
            const route = useRoute();
            async function filtruj()
            {
                //event.preventDefault();
                await router.push({name:'ogloszenia', query: {filters:(filtry.value)}});
                fetchData();
                //location.reload();
                    //if(rodzajeFiltrow.mode || rodzajeFiltrow.miasto || rodzajeFiltrow.subject || rodzajeFiltrow.miejsce || rodzajeFiltrow.cenaMin || rodzajeFiltrow.cenaMax || rodzajeFiltrow.zakres)
                    //{
                        //let response = await axios.get(`http://localhost:8000/api/offers/search/?query=${filtry.value}`);
                    //}
            }
            const fetchData = async () =>
            {
                try {
                    ogloszenia = [];
                    dane.value = [];
                    const response5 = await axios.get(`${endpoint}api/subjects/`);
                    let subjects = response5.data;
                    subjects.forEach(element => {
                        przedmioty.value.push(element.name);
                        przedmiotyNr.value.push(element.id);
                    })
                    const response4 = await axios.get(`${endpoint}api/regions/`);
                    let miasta1 = response4.data;
                    miasta1.forEach(element => {
                        miasta.value.push(element.city);
                    });
                    const response3 = await axios.get(`${endpoint}api/subjects/`);
                    const response2 = await axios.get(`${endpoint}api/users/`);
                    let response;

                    //if(route.query.search) console.log('huj');
                    let searchQuery = route.query.search;
                    //let filterQuery = route.query.filters;
                    //searchQuery='huje';
                    if(!searchQuery) response = await axios.get(`${endpoint}api/offers/sorted/`);
                    else response = await axios.get(`${endpoint}api/offers/search/?query=${searchQuery}`);

                    const response0 = await axios.get(`${endpoint}api/regions/`);

                    if(rodzajeFiltrow.mode || rodzajeFiltrow.miasto || rodzajeFiltrow.subject || rodzajeFiltrow.miejsce || rodzajeFiltrow.cenaMin || rodzajeFiltrow.cenaMax || rodzajeFiltrow.zakres)
                    {
                        response = await axios.get(`${endpoint}api/offers/sorted/${filtry.value}`);
                    }



                    ogloszenia = response.data;
                    let zweryfikowanyF = function(user)
                    {
                        let verified = user.verified_to;
                        verified = new Date(verified).getTime();
                        const currentDate = new Date();
                        let dzisiaj = new Date(currentDate.getTime());

                        let roznica = verified - dzisiaj;
                        if(roznica > 0) return true;
                        else return false;
                    }
                    let wyroznioneF = function(user)
                    {
                        let verified = user.outstanding_to;
                        verified = new Date(verified).getTime();
                        const currentDate = new Date();
                        let dzisiaj = new Date(currentDate.getTime());

                        let roznica = verified - dzisiaj;
                        if(roznica > 0) return true;
                        else return false;
                    }

                    ogloszenia.forEach(element => {

                        /*
                        oooo tutaj był ten jebany glicz
                        wyniknął z usuwania danych z naszej bazy danych
                        */
                        const index = response2.data.findIndex(user => user.id === element.user);
                        let user = response2.data[index];
                        //console.log(element)
                        //console.log(response2.data)

                        let subject = response3.data[element.subject-238];
                        let miasto = response0.data[user.occupation];

                        /* filtrowanie na frontendzie */
                        if (rodzajeFiltrow.miasto && miasto.id !== rodzajeFiltrow.miasto) return;

                        if (rodzajeFiltrow.miejsce == 'online' && !element.is_online) return;
                        if (rodzajeFiltrow.miejsce == 'uUcznia' && !element.is_stationary_at_student) return;
                        if (rodzajeFiltrow.miejsce == 'uKorepetytora' && !element.is_stationary_at_tutor) return;

                        let levels = element.levels;
                        if(rodzajeFiltrow.zakres == '1' && !levels.includes('1')) return;
                        if(rodzajeFiltrow.zakres == '2' && !levels.includes('2')) return;
                        if(rodzajeFiltrow.zakres == '3' && !levels.includes('3')) return;
                        if(rodzajeFiltrow.zakres == '4' && !levels.includes('4')) return;
                        if(rodzajeFiltrow.zakres == '5' && !levels.includes('5')) return;
                        if(rodzajeFiltrow.zakres == '6' && !levels.includes('6')) return;
                        if(rodzajeFiltrow.zakres == '7' && !levels.includes('7')) return;

                        let cena = parseInt(element.hour_price);
                        let cenaMinimum = 0;
                        let cenaMaximum = 9999999;
                        if(rodzajeFiltrow.cenaMin) cenaMinimum = parseInt(rodzajeFiltrow.cenaMin);
                        if(rodzajeFiltrow.cenaMax) cenaMaximum = parseInt(rodzajeFiltrow.cenaMax);

                        //console.log(cenaMinimum);
                        //console.log(cena);
                        //console.log(cenaMaximum);
                        if((rodzajeFiltrow.cenaMin || rodzajeFiltrow.cenaMax) && (cena < cenaMinimum || cena > cenaMaximum)) return;

                        const dana =
                        {
                            id: element.id,
                            awatar: require('./Obraz1.jpg'),/////////
                            username: user.name,
                            tytul: element.title,
                            miasto: miasto.city,
                            przedmiot: subject.name,
                            level: element.levels,
                            mode: element.mode,

                            telefon: user.phone_number,

                            wyksztalcenie: user.education,
                            doswiadczenie: user.experience,

                            online: element.is_online,
                            uUcznia: element.is_stationary_at_student,
                            uKorepetytora: element.is_stationary_at_tutor,

                            cena: element.hour_price,
                            dlugoscLekcji: element.lesson_time,

                            dostepnosc: element.when_he_can,

                            opis: element.description,

                            wyroznioneDo: wyroznioneF(element),
                            zweryfikowany: zweryfikowanyF(user),

                            wyswietlenia: element.number_of_views,
                            wiadomosci: element.number_of_opinions,
                        };

                    dane.value.push(dana);
                    });
                    setTimeout(() => {
                        zmienNav();
                    }, 10);
                    //console.log(dane.value[0].mode);
                }
                catch (error) {
                    console.error('Błąd podczas pobierania danych z API:', error);
                }
            };
            function zmienNav()
            {
                for(let i = 1; i <= parseInt(Math.ceil(dane.value.length/10)); i++)
                {
                    document.getElementById(`nav-list${i}`).addEventListener('click', function(){
                        poczatek.value = (i-1)*10;
                        for(let j = 1; j <= parseInt(dane.value.length/10 + 1); j++)
                        {
                            if(document.getElementById(`nav-list${j}`) == document.getElementById(`nav-list${i}`))
                            {
                                document.getElementById(`nav-list${j}`).style.color = '#1a9ddb';
                            }
                            else
                            {
                                document.getElementById(`nav-list${j}`).style.color = '#969696';
                            }
                        }
                    })
                }
            }
            onMounted(() =>
            {
                fetchData();
                //console.log(dane.value.length);
                //filtruj();
            });
            return { tab, numer, miasta, przedmioty, przedmiotyNr, dane, poczatek, koniec, filtruj, ...toRefs(rodzajeFiltrow), filtry };
        }
    }
</script>

<style lang="scss" scoped>
    @import '../styleFolder/style.scss';
    *
    {
        box-sizing: border-box;
    }
    .nav-list
    {
        cursor: pointer;
    }
    select:invalid
    {
        color: $border-color2 !important;
        opacity: 1;
    }
    input:invalid
    {
        color: $border-color2 !important;
        opacity: 1;
    }
    input::placeholder
    {
        color: $border-color2 !important;
        opacity: 1;
    }
    .greyC
    {
        color: $border-color2 !important;
        opacity: 1;
    }
    .container
    {
        margin-top: 10vh;
        display: grid;
        grid-template-columns: 100vw;
        grid-template-rows: auto;
        justify-content: center;
    }
    .lewy
    {
        & h1
        {
            width: 100%;
            text-align: center;
        }
        padding: 4%;
        border-right: solid 1px $border-color;
        & .rzeczy
        {
            & .dane
            {
                margin-top: 10%;
                margin-bottom: 10%;
                & label
                {
                    width: 100%;
                    padding-left: 5%;
                    font-weight: 600;
                }
                & select
                {
                    width: 100%;
                    height: 120%;
                    background-color: white;
                    border-radius: 100vw;
                    border: 1px solid $border-color;
                    padding-left: 5%;
                    font-size: 1rem;
                    color: $font-color;
                    outline: none;
                }
                & input
                {
                    width: 50%;
                    height: 120%;
                    background-color: white;
                    border: 1px solid $border-color;
                    padding-left: 5%;
                    font-size: 1rem;
                    color: $font-color;
                    outline: none;
                }
                & #do
                {
                    border-top-right-radius: 100vw;
                    border-bottom-right-radius: 100vw;
                }
                & #od
                {
                    border-top-left-radius: 100vw;
                    border-bottom-left-radius: 100vw;
                }
                & button
                {
                    width: 100%;
                    height: 180%;
                    color: white;
                    padding: 1.5%;
                    background-color: $main-color;
                    border-radius: 100vw;
                    border: 1px solid white;
                    cursor: pointer;
                    font-size: 1rem;
                    font-weight: bold;
                    transition: 0.3s;
                    text-decoration: none;
                    border: 1px solid $main-color;
                    &:hover
                    {
                        color: $main-color;
                        background-color: white;
                    }
                }
            }
            display: flex;
            flex-wrap: wrap;
            flex-direction: row;
            & div
            {
                width: 100%;
            }
        }
    }
    .main
    {
        padding: 4%;
        & h1, & h4
        {
            margin-bottom: 2%;
        }
    }
    .nawigacja
    {
        width: 90%;
        height: 1rem;
        & ul
        {
            width: 100%;
            display: flex;
            justify-content: center;
            gap: 1vw;
            & .klikniety
            {
                color: $main-color;
            }
            & li
            {
                color: $border-color2;
                list-style-type: none;
            }
        }
    }

    @media only screen and (min-width: 600px)
    {
        .container
        {
            margin-top: 10vh;
            display: grid;
            grid-template-columns: 100vw;
            grid-template-rows: auto auto;
            justify-content: center;
        }
    }

    @media only screen and (min-width: 1000px)
    {
        .container
        {
            margin-top: 100px;
            display: grid;
            grid-template-columns: 25vw 60vw;
            justify-content: center;
        }
    }
</style>