<template>
    <div class="prawy">
      <h2>Wiadomości</h2>
        <div class="settings">
            <div class="settings-menu">
                <div class="settings-menu-div1" :class="{ active: numer === 1 }" @click="nr1">Otrzymane</div>
                <div class="settings-menu-div2" :class="{ active: numer === 2 }" @click="nr2">Wysłane</div>
            </div>
            <div class="settings-main">
                <div v-if="numer==1">
                    <div v-for="element in odebrane" :key="element">
                        <jednaWiadomosc :info="element"/>
                    </div>
                </div>
                <div v-if="numer==2">
                    <div v-for="element in wyslane" :key="element">
                        <jednaWiadomosc :info="element"/>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import axios from 'axios';
    import { onMounted, ref, inject } from 'vue';
    import jednaWiadomosc from './jednaWiadomosc.vue';
    var numer = ref(1);
    var odebrane = ref([]);
    var wyslane = ref([]);
    export default
    {
        components:
        {
            jednaWiadomosc,
        },
        setup()
        {
            const endpoint = inject('g_endpoint');
            function getCookie(cname) 
            {
                let name = cname + "=";
                let ca = document.cookie.split(';');
                for(let i = 0; i < ca.length; i++) {
                    let c = ca[i];
                    while (c.charAt(0) == ' ') {
                    c = c.substring(1);
                    }
                    if (c.indexOf(name) == 0) {
                    return c.substring(name.length, c.length);
                    }
                }
                return "";
            }
            function nr1()
            {
                numer.value = 1;
            }
            function nr2()
            {
                numer.value = 2;
            }
            async function fetchData()
            {
                const response = await axios.get(`${endpoint}api/messages/`);
                var dane = response.data;
                dane.forEach(element => {
                    if(element.reciver == getCookie('userId'))
                    {
                        odebrane.value.push(element);
                    }
                    if(element.sender == getCookie('userId'))
                    {
                        wyslane.value.push(element);
                    }
                });
            }
            onMounted(() =>{
                fetchData();

            })
            return {numer, nr1, nr2, odebrane, wyslane}
        }
    }
</script>

<style lang="scss" scoped>
    @import '../styleFolder/style.scss';
    *
    {
        box-sizing: border-box;
    }
    .active
    {
        color: white;
        background-color: $main-color;
    }
    .settings
    {
        width: 100%;
        border: solid 1px $border-color;
        padding: 1vw;
        padding-top: 3vh;
        padding-bottom: 3vh;
        margin-top: 3vh;
        border-radius: 1vh;
        box-shadow: 0.5vh 0.5vh 1vh 1vh $border-color;
        &-menu
        {
            display: flex;
            flex-direction: row;
            margin-bottom: 5vh;
            background-color: $border-color;
            margin-left: -1vw;
            margin-right: -1vw;
            margin-top: -3vh;
            border-top-right-radius: 1vh;
            &-div1 
            {
                border-top-left-radius: 1vh;
            }
            &-div2 
            {
                border-top-right-radius: 1vh;
            }
            & div 
            {
                width: 50%;
                height: 8vh;
                display: flex;
                align-items: center;
                justify-content: center;
                transition: 0.2s ease-out;
                &:hover 
                {
                    background-color: $main-color;
                    color: white;
                }
            }
        }
        &-main
        {
            min-height: 60%;
        }
    }


    @media only screen and (min-width: 600px) 
    {

    }

    @media only screen and (min-width: 1000px) 
    {

    }

</style>