<template>
  <div class="main">
    <h1 class="main-element">Logowanie</h1>
    <form class="main-form" id="form" @submit="login">
      <div class="main-form-div">
        <label for="email" class="main-form-div-label">Adres e-mail {{ glicz }}</label>
        <div>
          <input id="email" type="email" name="email" v-model="email" />
        </div>
      </div>
      <div class="main-form-div">
        <label for="haslo" class="main-form-div-label">Hasło</label>
        <div>
          <input id="haslo" type="password" name="haslo" v-model="haslo" />
        </div>
      </div>
      <p class="main-p"><RouterLink to="/rejestracja">Nowe konto</RouterLink></p>
      <p class="main-p"><a>Nie pamiętam hasła</a></p>
      <button class="main-button">OK</button>
    </form>
  </div>
</template>

<script>
import { ref, onMounted, inject } from "vue";
import { useStore } from "vuex";
import axios from "axios";
import router from "@/router";

export default {
  name: 'stronaLogowaniaBody',
  setup() {
    const endpoint = inject('g_endpoint');
    const email = ref("");
    const haslo = ref("");
    const glicz = ref("");
    const czyOk = ref(1);
    const store = useStore();
    var userId;

    function setCookie(cname, cvalue, exdays) {
      const d = new Date();
      d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
      let expires = "expires=" + d.toUTCString();
      document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
    }
    function getCookie(cname) 
    {
      let name = cname + "=";
      let ca = document.cookie.split(';');
      for(let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) == ' ') {
          c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
          return c.substring(name.length, c.length);
        }
      }
      return "";
    }

    const fetchData = async () => 
    {
        try {
            const response2 = await axios.get(`${endpoint}api/users/`);
            let users = response2.data;
            users.forEach(element => {
              if(element.email == email.value) userId = element.id;
            });
        } 
        catch (error) {
            console.error('Błąd podczas pobierania danych z API:', error);
        }
    };
    
    onMounted(() => 
    {
        //fetchData();
        
    });
    


    async function login(event) {
      fetchData();
      event.preventDefault();
      const info = new FormData();
      info.append("email", email.value);
      info.append("password", haslo.value);
      czyOk.value = 1;

      await axios
        .post(`${endpoint}login/`, info)
        .catch((error) => {
          glicz.value = error.response.status;
          czyOk.value = 0;
        })
        .then((response) => {
          if (czyOk.value) {
            glicz.value = "";
            setCookie("czyZalogowano", response.status, 1);
            
            console.log("siema" + response.data);///////
            const csrfToken = response.data.csrf_token; 
            const drfToken = response.data.drf_token;
            
          if (csrfToken) {
            console.log(csrfToken);

            // Tutaj możesz wykorzystać CSRF token do dalszych działań, np. przekazać go w nagłówku żądania
            // Jeśli twoje API wymaga, aby CSRF token był przekazywany w nagłówku "X-CSRFToken", możesz to zrobić tak:
            axios.defaults.headers.common['X-CSRFToken'] = csrfToken; 
            axios.defaults.headers.common['Authorization'] = "Token " + drfToken;
            //store.commit('setTokenCSRF', csrfToken);
            setCookie('tokenCSRF', csrfToken, 9999);
            setCookie('tokenDRF', drfToken, 9999);
            setCookie('userId', userId, 9999);
            console.log(getCookie('tokenCSRF'));
            console.log(getCookie('tokenDRF'));
            console.log(getCookie('userId'));
          } else {
            console.log(response);
          }

            store.commit("setLoggedIn", true);
            router.push("/");
          } else {
            glicz.value = "Niepoprawny login lub hasło";
          }
        });
    }

    return { email, haslo, glicz, login };
  }
}

</script>
<style lang="scss" scoped>
  @import '../styleFolder/style.scss';
  *
  {
      margin: 0;
      padding: 0;
      user-select: none;
      font-family: Arial, Helvetica, sans-serif;
      box-sizing: border-box;
  }
  .main
  {
      min-height: 90vh;
      width: 100%;   
      padding-top: 15vh;
      &-element
      {
        text-align: center;
      }
      &-form
      {
        left:50%;
        max-width: 500px;
        position: relative;
        transform: translateX(-50%);
        height: 75%;
        margin-bottom: 2vh;
        &-div
        {
            margin-top: 5vh;
        }
      }
      &-button
      {
        margin-top: 4vh;
        left: 50%;
        position: relative;
        transform: translateX(-50%);
        width: 20%;
        height: 7vh;
        border-radius: 100px;
        border: none;
        background-color: $main-color;
        color: white;
        font-size: 1rem;
        font-weight: bold;
        transition: 0.3s ease-out;
        &:hover 
        {
            background-color: white;
            color: $main-color;
            border:1px solid $main-color;
            cursor: pointer;
        }
      }
      & input
      {
        width: 100%;
        height: 7vh;
        margin-top: 2vh;
        background-color: white;
        border-radius: 75px;
        text-align: left;
        padding-left: 5%;
        font-size: 1rem;
        color: $font-color;
        border: 1px solid $border-color;
        outline: none;
      }
      & label
      {
        padding-left: 5%;
      }
      &-p
      {
        text-align: center;
        margin-top: 0vh;
      }
  }
  a
  {
    color: $main-color;
    text-decoration: none;
  }
    
    @media only screen and (min-width: 600px) 
    {

    }

    @media only screen and (min-width: 1000px) 
    {

    }
</style>
