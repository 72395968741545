<template>
    <div class="container">
        <div class="main">
            <div class="heroImg">
                <div class="heroText">
                    <h1>Najlepsi korepetytorzy<br> w Twojej okolicy</h1>
                    <form @submit="$router.push({name: 'ogloszenia', query: {search:(szukaj)}})">
                        <input type="text" name="search" class="searchbar-bar" placeholder="Wpisz szukany przedmiot" v-model="szukaj">
                    </form>
                    <router-link :to="{name: 'ogloszenia', query: {search:(szukaj)}}"><button>Szukaj</button></router-link>
                </div>
            </div>
            <div class="categories" >
                <div class="categories-headline">
                    <div  v-for="element in lista" :key="element.nazwa" class="categories-headline-item" @click="zmien(element.id)">
                        <a>{{ element.nazwa }}</a>
                    </div>
                </div>
                <div class="categories-bottom">
                    <div class="categories-bottom-l0">
                        <ul>
                            <li v-for="element in kategorie[indeks]" :key="element">
                                {{ element }}
                            </li>
                        </ul>
                    </div>
                    <div class="categories-bottom-rozwin" @click="rozwin">
                        <p>Rozwiń więcej ▼</p>
                    </div>
                    <div class="categories-bottom-more" v-if="czyRozwiniete">
                        <ul>
                            <li v-for="element in kategorieExt[indeks]" :key="element">
                                {{ element }}
                            </li>
                        </ul>
                        <div class="categories-bottom-rozwin" @click="rozwin">
                        <p>Zwiń ▼</p>
                    </div>
                    </div>
                </div>
            </div>
        </div>
        <stronaZarobki></stronaZarobki>
        <stronaOpinie></stronaOpinie>
        <pobierzLink :link="false" v-if="false"></pobierzLink>
    </div>
</template>

<script>
    import {ref} from "vue";
    import stronaOpinie from '../stronaPozostale/stronaOpinie.vue';
    import stronaZarobki from '../stronaPozostale/stronaZarobki.vue';
    import pobierzLink from '../stronaKontaFolder/pobierzLink.vue';
    var indeks = ref(0);
    var szukaj = ref('');
    var lista = ref([
        {nazwa: "Języki obce", id: 0},
        {nazwa: "Ścisłe", id: 1},
        {nazwa: "Humanistyczne", id: 3},
        {nazwa: "Przyrodnicze", id: 5},
        {nazwa: "Sport", id: 6},
        {nazwa: "Sztuka", id: 4},
        {nazwa: "Ekonomiczne", id: 2},
        {nazwa: "Pozostałe", id: 7},
    ]);
    var kategorieExt = ref([
        [
        //"Język Afrikaans",
        //"Język Ajmara",
        //"Język Akan",
        "Język Albański",
        //"Język Amharski",
        "Język Angielski",
        "Język Arabski",
        //"Język Asamski",
        "Język Azerbejdżański",
        //"Język Bambara",
        //"Język Baskijski",
        //"Język Bengalski",
        //"Język Bhodżpuri",
        "Język Białoruski",
        //"Język Birmański",
        "Język Bośniacki",
        "Język Bułgarski",
        //"Język Cebuański",
        "Język Chiński",
        "Język Chorwacki",
        "Język Czeski",
        //"Język Dogri",
        "Język Duński",
        "Język Esperanto",
        "Język Estoński",
        //"Język Ewe",
        "Język Filipiński",
        "Język Fiński",
        "Język Francuski",
        //"Język Galicyjski",
        //"Język Ganda",
        "Język Grecki",
        //"Język Gruziński",
        //"Język Guarani",
        //"Język Gudżarati",
        //"Język Hausa",
        //"Język Hawajski",
        "Język Hebrajski",
        "Język Hindi",
        "Język Hiszpański",
        //"Język Hmong",
        //"Język Igbo",
        //"Język Ilokano",
        "Język Indonezyjski",
        "Język Irlandzki",
        "Język Islandzki",
        "Język Japoński",
        //"Język Jawajski",
        //"Język Jidysz",
        //"Język Joruba",
        //"Język Kannada",
        //"Język Kataloński",
        //"Język Kazachski",
        //"Język Keczua",
        //"Język Khmerski",
        //"Język Khosa",
        //"Język Kinya-ruanda",
        //"Język Kirgiski",
        //"Język Konkani",
        "Język Koreański",
        //"Język Korsykański",
        //"Język Kreolski haitański",
        //"Język Krio",
        //"Język Kurdyjski",
        //"Język Laotański",
        //"Język Lingala",
        "Język Litewski",
        //"Język Luksemburski",
        "Język Łaciński",
        "Język Łotewski",
        "Język Macedoński",
        //"Język Maithili",
        //"Język Malajalam",
        //"Język Malajski",
        //"Język Malediwski",
        //"Język Malgaski",
        //"Język Maltański",
        //"Język Manipuri",
        //"Język Maoryjski",
        //"Język Marathi",
        //"Język Mizo",
        //"Język Mongolski",
        //"Język Nepalski",
        "Język Niderlandzki",
        "Język Niemiecki",
        //"Język Njandża",
        "Język Norweski",
        //"Język Orija",
        //"Język Ormiański",
        //"Język Oromo",
        //"Język Paszto",
        //"Język Pendżabski",
        "Język Perski",
        "Język Polski",
        "Język Portugalski",
        "Język Rosyjski",
        "Język Rumuński",
        //"Język Samoański",
        //"Język Sanskryt",
        "Język Serbski",
        //"Język Shona",
        //"Język Sindhi",
        "Język Słowacki",
        "Język Słoweński",
        //"Język Somalijski",
        //"Język Sorani",
        //"Język Sotho południowy",
        //"Język Sotho północny",
        //"Język Suahili",
        //"Język Sundajski",
        //"Język Syngaleski",
        //"Język Szkocki gaelicki",
        "Język Szwedzki",
        //"Język Tadżycki",
        "Język Tajski",
        //"Język Tamilski",
        //"Język Tatarski",
        //"Język Telugu",
        //"Język Tigrinia",
        //"Język Tsonga",
        "Język Turecki",
        "Język Turkmeński",
        //"Język Ujgurski",
        "Język Ukraiński",
        //"Język Urdu",
        //"Język Uzbecki",
        "Język Walijski",
        "Język Węgierski",
        "Język Wietnamski",
        "Język Włoski",
        //"Język Zachodniofryzyjski",
        //"Język Zulu",

        ],
        [
        "Aerodynamika",
        "Algebra",
        "Analiza matematyczna",
        "Architektura",
        "Astronomia",
        "Automatyka",
        "Badania operacyjne",
        "Budownictwo",
        "Elektroenergetyka",
        "Elektronika",
        "Elektrotechnika",
        "Energetyka",
        "Energoelektronika",
        "Ergonomia",
        "Fizyka",
        "Geometria wykreślna",
        "Grafika komputerowa",
        "Grafika inżynierska",
        "Informatyka",
        "Konstrukcje inżynierskie",
        "Logika",
        "Logistyka",
        "Matematyka",
        "Matematyka dyskretna",
        "Programowanie",
        ],
        [
        "Bankowość",
        "Doradztwo zawodowe",
        "Ekonometria",
        "Ekonomia",
        "Ekonomia matematyczna",
        "Etykieta biznesu",
        "Finanse",
        "Marketing",
        "Mikroekonometria",
        "Przedsiębiorczość",
        "Rachunkowość",
        "Zarządzanie",
        ],
        [
        "Bibliotekoznawstwo",
        "Dziennikarstwo",
        "Etyka",
        "Filozofia",
        "Historia",
        "Język polski",
        "Kulturoznawstwo",
        "Literaturoznawstwo",
        "Logopedia",
        "Nauczanie początkowe",
        "Pedagogika",
        "Politologia",
        "Prawo",
        "Psychologia",
        "Socjologia",
        "Wos",
        ],
        [
        "Aktorstwo",
        "Ceramika",
        "Emisja głosu",
        "Fotografia",
        "Historia sztuki",
        "Malarstwo",
        "Muzyka",
        "Nauka śpiewu",
        "Rzeźba",
        ],
        [
        "Anatomia",
        "Biochemia",
        "Biologia",
        "Biologia molekularna",
        "Chemia",
        "Farmacja",
        "Fizjologia",
        "Fizjoterapia",
        "Florystyka",
        "Genetyka",
        "Geodezja",
        "Geografia",
        "Geologia",
        "Gospodarka przestrzenna",
        "Inżynieria chemiczna",
        "Medycyna",
        "Mikrobiologia",
        "Ochrona środowiska",
        "Przyroda",
        "Rolnictwo",
        ],
        [
        "Boks",
        "Brydż",
        "Fitness",
        "Jazda konna",
        "Jazda na nartach",
        "Joga",
        "Karate",
        "Kickboxing",
        "MMA",
        "Pływanie",
        "Szachy",
        "Sztuki walki",
        "Taniec",
        "Tenis",
        "Żeglarstwo",
        ],
        [
        "BHP",
        "Druk 3D",
        "Efektywna nauka",
        "Fryzjerstwo",
        "Hotelarstwo",
        "Kaligrafia",
        "Kosmetologia",
        "Kultura antyczna",
        "Motywacja",
        "Nauka jazdy",
        "Pozycjonowanie stron",
        "Produkcja filmu",
        "Prognozowanie",
        "Public relations",
        "Samoobrona",
        "Sztuka cyrkowa",
        "Szycie na maszynie",
        "Szydełkowanie",
        "Techniki pamięciowe",
        "Turystyka",
        "Tworzenie stron internetowych",
        "Wystąpienia publiczne",
        ]
    ]);
    var kategorie = ref([
        [
            "Język angielski",
            "Język niemiecki",
            "Język hiszpański",
            "Język francuski",
            "Język włoski",
        ],
        [
            "architektura",
            "fizyka",
            "informatyka",
            "matematyka",
            "programowanie",
        ],
        [
            "ekonometria",
            "ekonomia",
            "finanse",
            "marketing",
            "rachunkowość",
        ],
        [
            "historia",
            "język polski",
            "nauczanie początkowe",
            "prawo",
            "wos",
        ],
        [
            "aktorstwo",
            "emisja głosu",
            "fotografia",
            "malarstwo",
            "muzyka",
        ],
        [
            "biologia",
            "chemia",
            "geografia",
            "medycyna",
            "przyroda",
        ],
        [
            "fitness",
            "jazda konna",
            "pływanie",
            "szachy",
            "tenis",
        ],
        [
            "bhp",
            "fryzjerstwo",
            "kosmetologia",
            "nauka jazdy",
            "turystyka",
        ]
    ]);
    function zmien(x)
    {
        indeks.value = x;
    }
    var czyRozwiniete = ref(false);
    function rozwin()
    {
        czyRozwiniete.value = !czyRozwiniete.value;
        console.log(czyRozwiniete.value);
    }
    export default
    {
        name: 'stronaGlownaBody',
        components:
        {
            stronaOpinie,
            stronaZarobki,
            pobierzLink,
        },
        setup()
        {
            console.log(document.cookie);
            return{lista, kategorie, kategorieExt, indeks, zmien, rozwin, czyRozwiniete, szukaj}
        },
    }
</script>

<style lang="scss" scoped>
    @import '../styleFolder/style.scss';
    *
    {
        box-sizing: border-box;
        user-select: none;
        color: white;
        font-family: Arial, Helvetica, sans-serif;
    }
    .container
    {
        width: 100vw;
    }
    .main
    {
        width: 100%;
        min-height: 100vh;
        & .heroImg
        {
            background-image: url('./tlo2.jpg');
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center;
            position: relative;

            width: 100%;
            height: 100vh;

            & .heroText
            {
                text-align: center;
                position: relative;
                height: 100%;
                top: 65%;
                left: 50%;
                transform: translate(-50%, -50%);
                color: white;
                display: flex;
                flex-direction: column;
                align-items: center;
                gap: 5vh;

                & h1
                {
                    font-size: 10vw;
                    width: 75%;
                }

                & input
                {
                    width: 80vw;
                    height: 100%;
                    //max-height: 50px;
                    background-color: white;
                    border-radius: 100vw;
                    padding-left: 5%;
                    font-size: 1rem;
                    color: $font-color;
                    border: none;
                    outline: none;
                }

                & a, & form
                {
                    height: 10%;
                }

                & button
                {
                    width: 80vw;
                    height: 100%;
                    background-color: $main-color;
                    border-radius: 100vw;
                    border: none;
                    cursor: pointer;
                    font-size: 1.5rem;
                    font-weight: bold;
                    transition: 0.3s;

                    &:hover
                    {
                        background-color: white;
                        color: $main-color;
                    }
                }
            }

        }
    }
    .categories
    {
        background-color: white;
        width: 100%;
        position: relative;
        padding: 3%;
        border: 1px solid $main-color;
        transform: translateY(-10vh);
        text-align: center;
        margin: auto;

        &-bottom
        {
            &-rozwin
            {
                margin: 2vh;
                & p
                {
                    color: $font-color;
                }
            }

            &-more
            {
                color: $font-color;
                display: block;
                & ul
                {
                    display: flex;
                    flex-wrap:wrap;
                    flex-direction: column;
                    row-gap: 1.5vh;
                    padding-left: 0;
                }

                & ul li
                {
                    width: 100%;
                    color: $font-color;
                    text-align: center;
                    display: inline-block;
                    text-transform: lowercase;
                    transition: 0.3s ease-out;
                    cursor: pointer;
                    &:hover
                    {
                        color: $main-color;
                    }
                    &::first-letter {
                        text-transform: capitalize;
                    }
                }
            }

            &-l0
            {
                color: $font-color;
                margin-top: 5vh;
                & ul
                {
                    display: flex;
                    flex-direction: column;
                    justify-content: space-evenly;
                    row-gap: 2vh;
                    padding-left: 0;
                }

                & ul li
                {
                    color: $font-color;
                    list-style-type: none;
                    width: auto;
                    border: 1px solid $main-color;
                    border-radius: 100vw;
                    padding: 1vh 1vw;
                    font-size: 0.9rem;
                    transition: 0.3s ease-out;
                    cursor: pointer;
                    &:hover
                    {
                        background-color: $main-color;
                        color: white;
                    }
                    &::first-letter {
                        text-transform: capitalize;
                    }
                }
            }
        }

        &-headline
        {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            row-gap: 5vh;
            align-items: space-evenly;
            justify-content: space-between;
            padding-bottom: 2%;

            &-item
            {
                text-align: center;
                font-size: 1.3rem;
                border-bottom: solid 2px $border-color;
                transition: border 0.3s ease-in;
                padding-bottom: 1.5%;
                width: 47.5%;
                cursor: pointer;
                &:hover
                {
                    border-bottom: solid 2px $font-color;
                }
            }

            & a
            {
                color: $font-color;
            }
        }
    }

    @media only screen and (min-width: 600px)
    {
        .main
        {
            & .heroImg
            {
                & .heroText h1
                {
                    width: 90%;
                    font-size: 5rem;
                }
            }
        }
    }

    @media only screen and (min-width: 1000px)
    {
        .main
        {
            .heroImg
            {
                .heroText
                {
                    //gap: 10vh;
                    input, button
                    {
                        width: 60vw;
                    }
                    h1
                    {
                        width: 80%;
                        font-size: 6vw;
                    }
                }
            }
        }
        .categories
        {
            width: 90%;
            border-radius: 2vw;

            &-headline
            {
                &-item
                {
                    width: 12%;
                    font-size: 1.3vw;
                }
            }
            &-bottom
            {
                &-l0
                {
                    margin-top: 2vh;
                    & ul
                    {
                        flex-direction: row;
                        justify-content: space-between;
                    }

                    & ul li
                    {
                        width: 18%;
                        font-size: 100%;
                    }
                }
                &-more
                {
                    & ul
                    {
                        flex-direction: row;
                    }

                    & ul li
                    {
                        width: 19%;
                        text-align: left;
                    }
                }
            }
        }
    }
</style>

