<template>
    <stronaLogowaniaBody/>
  </template>
  
  <script>
      import stronaLogowaniaBody from './stronaLogowaniaFolder/stronaLogowaniaBody.vue'; 
      export default 
      {
        name: 'stronaLogowania',
        components: 
        {
          stronaLogowaniaBody,
  
        },
        setup()
        {
            document.title = 'Zaloguj się';
        }
      }
  </script>
  
  <style lang="scss" scoped>
  
  </style>
  
  