<template>
    <div class="container">
        <stronaKontaBody/>
    </div>
</template>

<script>
    import router from '@/router';
import stronaKontaBody from './stronaKontaFolder/stronaKontaBody';
import store from '@/store';
    export default
    {
        name: 'stronaKonta',
        components:
        {
            stronaKontaBody,

        },
        setup()
        {
            document.title = 'Profil użytkownika';
            if(!store.state.isLoggedIn) router.push('/logowanie');
        },
    }
</script>

<style lang="scss" scoped>
    .container
    {
        margin-top: 25%;
    }
    @media only screen and (min-width: 600px) 
    {
        .container
        {
            margin-top: 150px;
        }
    }
</style>