<template>
    <div class="container">
        <div class="main">
            <h1>Regulamin</h1>
            <p>Niniejszy regulamin opisuje zasady korzystania ze strony internetowej GigaKorki.pl</p>
            <p>Kontakt z administratorem jest możliwy pod zakładką Kontakt.</p>
            <h2>§1. Definicje</h2>
            <p>Na potrzeby niniejszej polityki prywatności, przyjmuje się następujące znaczenie poniższych pojęć:</p>
            <ul>
                <li>Strona – strona internetowa dostępna pod adresem GigaKorki.pl.</li>
                <li>Administrator – Rafał Mironko, właściciel Strony.</li>
                <li>Użytkownik – każdy podmiot korzystający ze Strony.</li>
            </ul>
            <h2>§2. Korzystanie ze Strony</h2>
            <ol>
                <li>Wyszukiwanie, przeglądanie ofert korepetycji oraz publikowanie ogłoszeń jest bezpłatne.</li>
                <li>Strona umożliwia Użytkownikom odnajdowanie ofert korepetycji oraz innych zajęć edukacyjnych, a także umożliwia kontaktowanie się z osobami, które zamieściły swoje ogłoszenia.</li>
                <li>Zabronione jest wysyłanie treści reklamowych oraz jakiegokolwiek spamu za pomocą panelu kontaktowego zamieszczonego w ogłoszeniu korepetytora. </li>
                <li>Wszelkie sugestie oraz uwagi dotyczące strony powinny być wysyłane do Administratora za pomocą formularza kontaktowego dostępnego w zakładce Kontakt.</li>
                <li>Administrator zastrzega możliwość wystąpienia przerw technicznych w działaniu strony.</li>
                <li>Administrator nie jest stroną w transakcjach dokonywanych pomiędzy Użytkownikami. Tego typu spory powinny być rozwiązywane pomiędzy Użytkownikami.</li>
            </ol>
            <h2>§3. Rejestracja na Stronie</h2>
            <ol>
                <li>Założenie konta na stronie GigaKorki.pl jest darmowe i dobrowolne. </li>
                <li>W celu założenia konta Użytkownik musi podać adres email którym będzie posługiwał się w serwisie oraz hasło, którym będzie logował się do systemu. </li>
                <li>Rejestracja na stronie umożliwia publikowanie ogłoszeń związanych z nauczaniem. </li>
                <li>Dany podmiot (osoba fizyczna, firma, szkoła itd.) może założyć tylko jedno konto. </li>
            </ol>
            <h2>§4. Dodawanie ogłoszeń</h2>
            <ol>
                <li>Użytkownik może dodać jedno ogłoszenie w danej kategorii w wybranym przez siebie mieście. </li>
                <li>Użytkownik zobowiązuje się zamieszczać ogłoszenia, które nie wprowadzają innych osób w błąd. W szczególności dotyczy to dbałości o poprawność wprowadzonych danych oraz wyborów odpowiednich kategorii i miast gdzie dane ogłoszenie będzie się wyświetlało. </li>
                <li>Ogłoszenia muszą być związane z usługami nauczania. </li>
                <li>W ogłoszeniach zabronione jest publikowanie: </li>
                <ul>
                    <li>treści zabronionych przez przepisy prawa (np. oferowanie pisania prac dyplomowych lub kopiowanie części treści ogłoszeń innych podmiotów), </li>
                    <li>wulgaryzmów, gróźb, treści obraźliwych i naruszających prawa osób trzecich, </li>
                    <li>treści wprowadzających w błąd celem odniesienia korzyści majątkowych lub wizerunkowych, </li>
                    <li>reklam innych stron internetowych. </li>
                </ul>
                <li>Treść ogłoszenia należy pisać zachowując zasady języka polskiego. W szczególności dotyczy to staranności o użycie polskich znaków czy też nienadużywanie wielkich liter, gdy nie wymaga tego ich poprawna pisownia. </li>
                <li>Administrator zastrzega sobie prawo do moderacji ogłoszeń, w tym edytowania lub usunięcia ogłoszenia. </li>
            </ol>
            <h2>§5. Wyróżnianie ogłoszeń</h2>
            <ol>
                <li>Wyróżnianie ogłoszeń jest dobrowolne. </li>
                <li>Wyróżnienie ogłoszenia powoduje wyższą pozycję na liście wyświetlanych ofert w danej kategorii i/lub mieście. </li>
                <li>W przypadku kilku ofert wyróżnionych w danej kategorii czynnikiem decydującym o tym, które ogłoszenie znajduje się wyżej, jest status Zweryfikowanego Korepetytora. </li>
                <li>Okres wyróżnienia można w dowolnym momencie wydłużyć. </li>
                <li>Zakupione okresy wyróżnień sumują się. </li>
                <li>Płatności za wyróżnianie ogłoszeń odbywają się za pośrednictwem operatora płatności PayU. </li>
                <li>Wyróżnienie ogłoszenia rozpoczyna się po zaksięgowaniu wpłaty przez operatora płatności. W przypadku przelewów błyskawicznych jest to zazwyczaj kilka minut, natomiast w przypadku przelewów tradycyjnych okres ten może wydłużyć się nawet do 72 godzin (dni robocze). </li>
                <li>Reklamacje oraz uwagi dotyczące zakupu wyróżnień powinny być składane za pomocą formularza kontaktowego dostępnego na stronie. </li>
                <li>Cennik wyróżnienia (brutto):</li>
                <ul>
                    <li>1 miesiąc - 7 zł</li>
                    <li>2 miesiące - 12 zł</li>
                    <li>3 miesiące - 18 zł</li>
                    <li>6 miesięcy - 33 zł</li>
                    <li>12 miesięcy - 60 zł</li>
                </ul>
            </ol>
            <h2>§6. Zweryfkowany Korepetytor</h2>
            <ol>
                <li>Weryfikacja konta jest dobrowolna</li>
                <li>Weryfikacja powoduje wyższą pozycję wszystkich ogłoszeń na liście wyświetlanych ofert, a także oznaczenie przy awatarze Korepetytora i na ogłoszeniu</li>
                <li>Kolejność wyświetlania ofert na liście: </li>
                <ul>
                    <li>Wyróżnione ogłoszenie, Zweryfkowany Korepetytor</li>
                    <li>Tylko wyróżnione ogłoszenie</li>
                    <li>Tylko Zweryfikowany Korepetytor</li>
                    <li>Pozostałe ogłoszenia</li>
                </ul>
                <li>Jeśli ogłoszenia spełniają te same warunki wyróżnienia, wysokość pozycji na liście wyświetlanych ogłoszeń będzie losowa</li>
                <li>Cennik Weryfikacji konta (brutto)</li>
                <ul>
                    <li>1 miesiąc - 10 zł</li>
                    <li>2 miesiące - 18 zł</li>
                    <li>3 miesiące - 27 zł</li>
                    <li>6 miesięcy - 50 zł</li>
                    <li>12 miesięcy - 89 zł</li>
                </ul>
            </ol>
            <!-- <h2>§7. Odświeżenie ogłoszenia</h2>
            <ol>
                <li>Odświeżanie ogłoszeń jest dobrowolne</li>
                <li>Odświeżenie ogłoszenia powoduje jednorazowe wyświetlanie się ogłoszenia najwyżej listy wyświetlanych ofert w swojej kategorii ogłoszenia i wyróżnienia</li>
                <li>Odświeżenie ogłoszeń można kupować w pakietach</li>
                <li>Cennik</li>
                <ul>
                    <li>1 odświeżenie - 2zł</li>
                    <li>2 odświeżenia - 3zł</li>
                    <li>5 odświeżenia - 6zł</li>
                    <li>10 odświeżeń - 12zł</li>
                    <li>20 odświeżeń - 20zł</li>
                </ul>
            </ol> -->
            <h2>§7. Dodawanie opinii</h2>
            <ol>
                <li>Użytkownik ma możliwość pozostawienia opinii o korepetytorze pod danym ogłoszeniem o ile korzystał wcześniej z jego usług, a treść opinii jest zgodna z prawdą. </li>
                <li>Użytkownik bierze na siebie pełną odpowiedzialność za treść pozostawionej opinii. </li>
                <li>Treść opinii będzie opublikowana na Stronie po zatwierdzeniu jej przez Administratora. </li>
                <li>Administrator zastrzega sobie prawo do edytowania lub usuwania opinii, w szczególności gdy zawiera ona wulgaryzmy bądź też istnieje podejrzenie niezgodności treści opinii z prawdą. </li>
            </ol>
        </div>
    </div>
</template>

<script>

</script>

<style lang="scss" scoped>
    .container
    {
        margin-top: 10vh;
        display: flex;
        justify-content: center;
        min-height: 80vh;
    }
    .main
    {
        margin-top: 10vh;
        margin-bottom: 10vh;
        width: 60vw;
        & ul li, ol li
        {
            line-height: 2rem;
        }
        & p 
        {
            line-height: 2rem;
        }
        & h2 
        {
            font-size: 2rem;
            line-height: 3rem;
        }
        & h1
        {
            text-align: center;
            font-size: 3rem;
            line-height: 4rem;
        }
    }
    
    @media only screen and (min-width: 600px) 
    {

    }

    @media only screen and (min-width: 1000px) 
    {

    }
</style>