<template>
    <div class="prawy">
        <div class="srodek"><h1>Twój link afiliacyjny:</h1></div> 
        <div class="srodek"><a id="link">http://hujhuj/kupa</a></div>
        <div class="srodek"><button class="butt" @click="wyloguj">Skopiuj link</button></div>
        <div class="srodek">
          <h1>Dlaczego warto skorzystać z linku afiliacyjnego?</h1>
        </div>
        <p>Jeśli chcesz zarobić nieograniczoną ilość pieniędzy, nie ruszając się z kanapy, to jest to idealna propozycja dla Ciebie.</p><br>
        <p>Ponadto, nie potrzebujesz do tego ŻADNYCH umiejętności.</p><br>
        <p>Wystarczy, że użyjesz swojego linku afiliacyjnego.</p><br>
        <p>Każda osoba, która zarejestruje się na naszej stronie przez kliknięcie Twojego linku, a następnie wyda tutaj pieniądze, przyniesie Ci zysk.</p><br>
        <p>Przykładowo, Twój link klika 100 osób. Każda z nich wydaje 50 złotych. Ty otrzymujesz połowę z tych pieniędzy – w tym wypadku 2500 złotych! </p><br>
        <div class="srodek">
        <h4>Ilość osób: {{ ludzie }}</h4>
        <input type="range" v-model="ludzie" min="5" max="1000">
        <h4>Wydana kwota: {{ wydali }} zł</h4>
        <input type="range" v-model="wydali" min="7" max="89">
        <h4>Dostajesz: <h2>{{ dostajesz }} zł</h2></h4>
        </div>
        <p>Co najlepsze…</p><br>
        <p>Pieniądze otrzymujesz prosto na konto! Możesz je wykorzystać na dowolny cel, nawet niezwiązany z naszą stroną! </p><br>
        <div class="srodek"><button class="butt" @click="wyloguj">Skopiuj link</button></div>
        <p>Ponadto, możesz bez wysiłku wykorzystać swój link do zarobienia jeszcze większej ilości gotówki.</p><br>
        <p>Wykorzystaj do tego swoje social media!</p><br>
        <p>Jeśli Twój Instagram ma dużo followersów, bez problemu możesz promować na nim swój link.</p><br>
        <p>Możesz też wykorzystać TikToka – viralowy tiktok z 1 000 000 wyświetleń, mający w opisie Twój link, mógłby Ci przynieść dziesiątki tysięcy złotych.</p><br>
        <p>DZIESIĄTKI.</p><br>
        <p>TYSIĘCY.</p><br>
        <p>ZŁOTYCH.</p><br>
        <div class="srodek"><button class="butt" @click="wyloguj">Skopiuj link</button></div>
        <p>Ale uwaga.</p><br>
        <p>Taka oferta nie będzie trwała długo.</p><br>
        <p>Nie zwlekaj i wykorzystaj swój link do zdobycia jak największej ilości gotówki już teraz!</p><br>
        <p>Oficjalnie oddajemy w Twoje ręce potężne narzędzie do bezpiecznego i prostego zarobienia znacznych pieniędzy.</p><br>
        <p>Wszystko zależy od Ciebie…</p><br>
        <p>Wykorzystaj je jak najlepiej</p><br>
        <div class="srodek"><button class="butt" @click="wyloguj">Skopiuj link</button></div><br><br>
        <h3>Co to jest link afiliacyjny?</h3>
        <ul>
          <li>Link afiliacyjny pozwala Ci na otrzymanie 50% kwoty, jaką polecony przez Ciebie użytkownik wyda na naszej stronie. </li>
          <li>Kwota jest naliczana przez miesiąc od momentu, kiedy użytkownik zarejestruje się z Twojego linku.</li>
          <li>Po upłynięciu miesiąca nadal możesz odebrać pieniądze za danego użytkownika, kwota ta po prostu już się nie będzie powiększać.</li>
          <li>Raz w tygodniu możesz nas poinformować, że chcesz wypłacić pieniądze. Kiedy zwryfikujemy Twoje dane, wypłatę otrzymasz prosto na konto (numer konta możesz podać w ustawieniach użytkownika)</li>
          <li>Aktualną należną Ci kwotę możesz zobaczyć w zakładce Poleć nas</li>
        </ul>
    </div>
  </template>
  
  <script>
import router from '@/router';
import { computed, onMounted, ref } from 'vue';

  
  export default {
    name: 'stronaKontaWyloguj',
    props:
    {
      link:
      {
        default: true
      },
    },
    methods: {
        
    },
    
    setup(props)
    {
      var ludzie = ref(100);
      var wydali = ref(50);
      var dostajesz =computed(() => {
        return ludzie.value*wydali.value/2;
      })
      function wyloguj() 
        {
          let tekst;
          if(props.link)
          {
            tekst = document.getElementById('link').innerText;
            navigator.clipboard.writeText(tekst);
          }
          else router.push('/rejestracja');
        }
      onMounted(() => {
        let buttony;
        if(!props.link)
        {
          document.getElementById('link').innerText="Zarejestruj się, aby otrzymać link afiliacyjny";
          buttony = document.getElementsByClassName('butt');
          const buttonyArray = Array.from(buttony);
          buttonyArray.forEach(element => {
            element.innerText = "Zarejestruj się";
          });
        }
      })
      return {ludzie, wydali, dostajesz, wyloguj};
    }
  };
  </script>
  
  <style lang="scss" scoped>
  @import '../styleFolder/style.scss';
  .prawy
  {
    width: 60%;
    margin-left: 20%;
    margin-bottom: 10vh;
    min-height: 40vh;
    display: flex;
    gap: 2vh;
    flex-direction: column;
    justify-content: space-evenly;
    & .srodek
    {
      width: 100%;
      text-align: center;
      & input
      {
        width: 100%;
      }
    }
    & .butt
    {
        width: 33%;
        color: white;
        padding: 1.5%;
        background-color: $main-color;
        border-radius: 100vw;
        border: 1px solid white;
        cursor: pointer;
        font-size: 1rem;
        font-weight: bold;
        transition: 0.3s;
        text-decoration: none;
        &:hover {
          color: $main-color;
          background-color: white;
          border: 1px solid $main-color;
        }
    }
  }
    
    @media only screen and (min-width: 600px) 
    {

    }

    @media only screen and (min-width: 1000px) 
    {

    }
  </style>
  