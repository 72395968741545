<template>
    <div class="contain">
      <div class="prawy">
        Wyloguj
        <button class="butt" @click="wyloguj">Wyloguj</button>
      </div>
    </div>
  </template>
  
  <script>
  import axios from 'axios';
  import router from '@/router';
import { inject } from 'vue';
  
  export default {
    name: 'stronaKontaWyloguj',
    methods: {
      async wyloguj() {
        try {
          const endpoint = inject('g_endpoint');
          await this.$store.dispatch('logout');
  
          await axios.get(`${endpoint}logout/`);

        document.cookie = `tokenCSRF=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
        document.cookie = `tokenDRF=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
        document.cookie = `userId=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
        document.cookie = `pokazWarning=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;

          localStorage.clear();
          sessionStorage.clear();
  
          router.push('/');
        } catch (error) {
          console.error(error);
        }
      },
    },
  };
  </script>
  
  <style lang="scss" scoped>
  @import '../styleFolder/style.scss';
  .contain {
    margin-top: 15vh;
    width: 100%;
    height: 100%;
  }
  .prawy
  {
    text-align: center;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    //align-items: center;
    button 
    {
      background-color: #336699; /* Ciemnopastelowy niebieski */
      color: white;
      padding: 10px 20px;
      border: none;
      border-radius: 5px;
      font-size: 1rem;
      cursor: pointer;
      transition: background-color 0.3s ease-in-out;
      &:hover {
      background-color: #264d80; /* Ciemniejszy odcień niebieskiego */
      }
      &:active {
      background-color: #1a3353; /* Jeszcze ciemniejszy odcień niebieskiego */
    }
    }
  }
    
    @media only screen and (min-width: 600px) 
    {

    }

    @media only screen and (min-width: 1000px) 
    {

    }
  </style>
  