<template>
    <div class="container">
        <div class="main">
            <div class="main-header">
                <h1>Kontakt</h1>
                <p>Masz pytania? Jesteśmy gotowi Ci pomóc</p>
            </div>
            <div class="main-link">
                <a href="mailto:gigakorki@gmail.com" target="_blank">Skontaktuj się z nami przez maila!</a>
            </div>
            <div class="main-link">
                <a href="https://www.instagram.com/gigakorki/" target="_blank">Skontaktuj się z nami przez Instagrama!</a>
            </div>
            <div class="main-link">
                <a href="https://www.facebook.com/profile.php?id=100094073884897" target="_blank">Skontaktuj się z nami przez Facebooka!</a>
            </div>
        </div>
    </div>
</template>

<script>
    // Tu możesz dodać swoje skrypty, jeśli są potrzebne
</script>

<style lang="scss" scoped>
    @import '../styleFolder/style.scss';
    .container {
        margin-top: 10vh;
        min-height: 80vh;
        display: flex;
        justify-content: center;
    }
    .main {
        width: 70vw;
        display: flex;
        flex-direction: column;
        margin-top: 10vh;
        gap: 5vh;
        & .main-header {
            h1 {
                font-size: 2rem;
            }
            p {
                font-size: 1rem;
                color: $font-color;
            }
        }
        & .main-link {
            a {
                text-decoration: none;
                color: $font-color;
                &:hover {
                    color: $main-color;
                    transition: 0.2s ease-out;
                }
            }
        }
    }
    
    @media only screen and (min-width: 600px) 
    {

    }

    @media only screen and (min-width: 1000px) 
    {

    }
</style>
