<template>
    <a>
        <div class="ad">
            <div class="tytul">
                {{ $props.ogloszenieInfo.tytul }}
            </div>
            <div class="wyroznienie">
                <a v-if="!wyroznienie" class="stale">
                    Ogłoszenie nie jest wyróżnione.
                </a>
                <a v-else class="stale1">
                    Ogłoszenie jest wyróżnione!
                </a>
                
                
            </div>
            <div class="akcje">
                <router-link class="klikable" :to="{name: 'podglad', params: {id: $props.ogloszenieInfo.id, ogloszenieInfo: $props.ogloszenieInfo.id}}" :id="`podglad`">
                    Podgląd
                </router-link>
                <a class="klikable"  :id="`edytuj${$props.numerListy}`">
                    Edytuj
                </a>
                <a class="klikable" :id="`wyroznienie${$props.numerListy}`">
                    Wyróżnij ogłoszenie<br>
                </a>
                <router-link to="wiadomosci" class="klikable" :id="`wiadomosci${$props.numerListy}`">
                    Otrzymane wiadomości<br>
                </router-link>
                <!-- <a class="klikable" :id="`weryfikacja${$props.numerListy}`">
                    Zweryfikuj się<br>
                </a> -->
                <a class="klikable" :id="`usun${$props.numerListy}`" >
                    Usuń
                </a>
            </div>
            <div class="statystyki">
                <a>Wyświetlenia: {{ wyswietlenia }}</a>
                <a>Wiadomości: {{ wiadomosci }}</a>
            </div>
        </div>
    </a>
</template>
 
<script>
import axios from 'axios';
import { onMounted, toRefs, reactive, inject } from 'vue';
import router from '@/router';

    function getCookie(cname) 
            {
            let name = cname + "=";
            let ca = document.cookie.split(';');
            for(let i = 0; i < ca.length; i++) {
                let c = ca[i];
                while (c.charAt(0) == ' ') {
                c = c.substring(1);
                }
                if (c.indexOf(name) == 0) {
                return c.substring(name.length, c.length);
                }
            }
            return "";
    }
    
    
        export default {
        name: 'stronaOgloszeniaBody',
        props: ['ogloszenieInfo', 'numerListy'],
        setup(props) 
        {
            const endpoint = inject('g_endpoint');
            var propsy = reactive({
                user: "",
                tytul: "",
                wyroznienie: "",
                wyswietlenia: "",
                wiadomosci: "",
            })
            async function edytuj()
            {
                const response = await axios.get(`${endpoint}api/offers/${props.ogloszenieInfo.id}/`);
                let dane = response.data;
                propsy.user = dane.user;
                propsy.tytul = dane.title;
                propsy.wyroznienie = dane.outstanding_to;
                propsy.wyswietlenia = dane.number_of_views;
                propsy.wiadomosci = dane.number_of_opinions;
            }
            edytuj();
            async function wyroznij()
            {
                //let data = new Date("2023-08-23");
                router.push({name: 'wyroznijOgloszenie', query:{nrOgloszenia: props.ogloszenieInfo.id}});
            }
            function edycjaA()
            {
                router.push({path:"/konto/dodajOgloszenie", query:{offerId: props.ogloszenieInfo.id}});
            }
            async function usunA()
            {

                await axios.delete(`${endpoint}api/offers/${props.ogloszenieInfo.id}/`,{
                withCredentials: true,
                headers: {
                    'X-CSRFToken': `${getCookie('tokenCSRF')}`,
                    'Authorization': `Token ${getCookie('tokenDRF')}`,
                    'Content-Type': "application/json"
                }
                })
                .catch(error => {
                    console.error(error);
                }) 
                location.reload();
            }
            onMounted(() => {
                document.getElementById(`usun${props.numerListy}`).addEventListener('click', usunA);
                document.getElementById(`wyroznienie${props.numerListy}`).addEventListener('click', wyroznij);
                document.getElementById(`edytuj${props.numerListy}`).addEventListener('click', edycjaA);
                //document.getElementById(`usun${props.numerListy}`).style.color='red';
            });
            return {usunA, edytuj, ...toRefs(propsy), wyroznij, edycjaA}
        },
    }
</script>

<style lang="scss" scoped>
    @import '../styleFolder/style.scss';
    *
    {
        color: $font-color;
        text-decoration: none;
        box-sizing: border-box;
    }

    /* .ad
    {
        & .akcje
        {
            width: 100%;
            display: flex;
            justify-content: space-evenly;
        }
        & .wyroznienie
        {
            width: 100%;
            display: flex;
            justify-content: space-between;
        }
        & .statystyki
        {
            width: 100%;
            display: flex;
            justify-content: space-evenly;
            //margin-bottom: -2%;
            margin-top: 1%;
            //padding-bottom: 0.5%;
            padding-top: 0.5%;
        }
    } */
    .ad
    {
        width: 100%;
        background-color: transparent;
        display: flex;
        flex-wrap: wrap;
        gap: 2vh;
        border: 1px solid $border-color;
        box-shadow: 0.5vh 0.5vh 1vh 1vh $border-color;
        border-radius: 2vh;
        margin-top: 2%;
        margin-bottom: 8%;
        & div 
        {
            padding-left: 1%;
            padding-right: 1%;
        }
        & .akcje
        {
            width: 100%;
            display: flex;
            justify-content: space-evenly;
        }
        & .wyroznienie
        {
            width: 100%;
            display: flex;
            justify-content: space-between;
        }
        .tytul
        {
            white-space: nowrap;
            overflow: auto;

            width: 100%;
            background-color: $main-color;
            color: white;
            border-top-left-radius: 2vh;
            border-top-right-radius: 2vh;
            border: 1px solid $main-color;
            padding: 1%;
            padding-left: 2%;

            display: flex;
            align-items: center;
        }
        & a.stale
        {
            display: flex;
            align-items: center;
            color: white;
            background-color: $warning-color;
            border-radius: 100vw;
            padding: 0.2%;
            padding-left: 1%;
            padding-right: 1%;
            line-height: 1.5rem;
            &1
            {
                color: $main-color;
                background-color: transparent;
            }
        }
        & .statystyki
        {
            width: 100%;
            display: flex;
            justify-content: space-evenly;
            margin-bottom: 1%;
            margin-top: 1%;
            //padding-bottom: 0.5%;
            padding-top: 0.5%;
        }
        & a.klikable
        {
            //line-height: 1.5rem;
            width: 40%;
            display: flex;
            align-items: center;
            justify-content: center;
            color: white;
            transition: 0.3s ease-out;
            cursor: pointer;
            background-color: $main-color;
            border: 1px solid $main-color;
            border-radius: 100vw;
            padding: 0.2%;
            padding-left: 2%;
            padding-right: 2%;
            &:hover 
            {
                color: $main-color;
                background-color: white;
            }
        }
    }
    
    @media only screen and (min-width: 600px) 
    {
        .ad
        {
            width: 100%;
            height: 25vh;
            margin-bottom: 5vh;
        }
    }

    @media only screen and (min-width: 1000px) 
    {
        .ad
        {
            width: 100%;
            height: auto;
            padding-bottom: 2%;
            border: 1px solid $border-color;
            box-shadow: 0.5vh 0.5vh 1vh 1vh $border-color;
            background-color: transparent;
            border-radius: 2vh;
            margin-top: 2%;
            margin-bottom: 8%;
            flex-direction: row;
            & div 
            {
                padding-left: 1%;
                padding-right: 1%;
            }
            & .tytul
            {
                width: 200%;
                background-color: $main-color;
                color: white;
                border-top-left-radius: 2vh;
                border-top-right-radius: 2vh;
                border: 1px solid $main-color;
                padding: 1%;
                padding-left: 2%;
            }
            & .akcje
            {
                width: 100%;
                display: flex;
                justify-content: space-evenly;
            }
            & .wyroznienie
            {
                width: 100%;
                display: flex;
                justify-content: space-between;
            }
            & .statystyki
            {
                width: 100%;
                display: flex;
                justify-content: space-evenly;
                //margin-bottom: -2%;
                margin-top: 1%;
                //padding-bottom: 0.5%;
                padding-top: 0.5%;
            }
            & a.klikable
            {
                width: auto;
            }
        }

    }
</style>