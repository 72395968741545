<template>
    <div class="container">
        <div class="main">
            <h1>Polityka plików cookies</h1>
            <h2>§1. Wstęp</h2>
            <p>Niniejsza Polityka cookies opisuje sposób, w jaki strona internetowa GigaKorki.pl wykorzystuje pliki cookies oraz podobne technologie do zbierania danych i śledzenia aktywności użytkowników. Polityka ta ma na celu zapewnienie jasnych informacji dotyczących plików cookies oraz ochrony prywatności użytkowników.</p>
            <h2>§2. Definicje</h2>
            <p>W niniejszej Polityce cookies stosujemy następujące terminy:</p>
            <ul>
                <li>Pliki cookies: to małe pliki tekstowe przechowywane na urządzeniu użytkownika, które zawierają dane dotyczące aktywności na stronie.</li>
                <li>Serwis: oznacza stronę internetową GigaKorki.pl.</li>
                <li>Administrator: oznacza Rafała Mironko, właściciela strony GigaKorki.pl.</li>
                <li>Użytkownik: oznacza każdego podmiotu korzystającego ze strony GigaKorki.pl.</li>
            </ul>
            <h2>§3. Rodzaje plików cookies</h2>
            <p>Strona GigaKorki.pl używa różnych rodzajów plików cookies w celu usprawnienia działania serwisu oraz świadczenia lepszych usług. Pliki cookies stosowane na naszej stronie mogą być:</p>
            <ol>
                <li>Pliki cookies niezbędne: Są to pliki cookies, które są niezbędne do zapewnienia pełnej funkcjonalności naszej strony. Dzięki nim użytkownicy mogą korzystać z podstawowych usług, takich jak logowanie do konta, dodawanie ogłoszeń czy korzystanie z panelu kontaktowego.</li>
                <li>Pliki cookies analityczne: Te pliki cookies pomagają nam śledzić aktywność użytkowników na stronie, zbierając anonimowe informacje o tym, jak strona jest używana. Pozwala to na analizę ruchu na stronie, zrozumienie preferencji użytkowników i ulepszanie funkcjonalności.</li>
                <!-- <li>Pliki cookies reklamowe: Stosujemy pliki cookies, które pozwalają na wyświetlanie spersonalizowanych reklam użytkownikom na podstawie ich aktywności na naszej stronie. Dzięki nim reklamy są bardziej dopasowane do zainteresowań użytkowników.</li> -->
            </ol>
            <h2>§4. Zarządzanie plikami cookies</h2>
            <p>Użytkownicy mogą samodzielnie zarządzać ustawieniami plików cookies w swojej przeglądarce internetowej. Można zablokować lub ograniczyć wykorzystywanie plików cookies, wybierając odpowiednie opcje w ustawieniach przeglądarki. Należy jednak pamiętać, że zablokowanie plików cookies może wpłynąć na pełną funkcjonalność strony i ograniczyć dostęp do niektórych usług.</p>
            <h2>§5. Wykorzystywanie plików cookies przez osoby trzecie</h2>
            <p>Niektóre funkcje na stronie GigaKorki.pl mogą pochodzić od zewnętrznych dostawców, którzy również mogą stosować własne pliki cookies. W takich przypadkach, polityka cookies osób trzecich może różnić się od naszej, dlatego zachęcamy użytkowników do zapoznania się z politykami cookies tych dostawców.</p>
            <h2>§6. Zgoda na pliki cookies</h2>
            <p>Korzystając z naszej strony, użytkownik wyraża zgodę na wykorzystywanie plików cookies zgodnie z niniejszą Polityką cookies. W przypadku braku zgody na stosowanie plików cookies, prosimy o odpowiednie skonfigurowanie przeglądarki lub powstrzymanie się od korzystania z naszej strony.</p>
            <h2>§7. Zmiany w polityce cookies</h2>
            <p>Polityka cookies może być okresowo aktualizowana w celu odzwierciedlenia zmian w prawie lub naszych praktykach związanych z plikami cookies. W przypadku wprowadzenia istotnych zmian w polityce cookies, użytkownicy zostaną poinformowani na naszej stronie lub poprzez inne odpowiednie środki.</p>
        </div>
    </div>
</template>

<script>

</script>

<style lang="scss" scoped>
    .container
    {
        margin-top: 10vh;
        display: flex;
        justify-content: center;
        min-height: 80vh;
    }
    .main
    {
        margin-top: 10vh;
        margin-bottom: 10vh;
        width: 60vw;
        & ul li, ol li
        {
            line-height: 2rem;
        }
        & p 
        {
            line-height: 2rem;
        }
        & h2 
        {
            font-size: 2rem;
            line-height: 3rem;
        }
        & h1
        {
            text-align: center;
            font-size: 3rem;
            line-height: 4rem;
        }
    }
    
    @media only screen and (min-width: 600px) 
    {

    }

    @media only screen and (min-width: 1000px) 
    {

    }
</style>