<template>
  <stronaRejestracjiBody/>
</template>

<script>
    import stronaRejestracjiBody from './stronaRejestracjiFolder/stronaRejestracjiBody.vue'; 
    export default 
    {
      name: 'stronaRejestracji',
      components: 
      {
        stronaRejestracjiBody,

      },
        setup()
        {
            document.title = 'Zarejestruj się';
        }
    }
</script>

<style lang="scss" scoped>

</style>

