<template>
    <div class="prawy">
      <h2>Ustawienia konta</h2>
      <h3>Zarządzaj swoimi danymi</h3>
      <div class="settings">
        <div class="settings-menu">
          <div
            class="settings-menu-div1"
            :class="{ active: numer === 1 }"
            @click="nr1"
          >
            Profil
          </div>
          <div @click="nr2" class="settings-menu-div2" :class="{ active: numer === 2 }">Hasło</div>
        </div>
        <form class="settings-form" id="form" v-if="numer === 1">
                <div class="img-div">
                    <label for="awatar">
                        <input type="file" id="awatar" name="awatar"/>
                        <img src="../staleRzeczy/default.png"/>
                    </label>
                </div>
                <div class="img-div">
                    <label for="awatar">PNG, JPG, JPEG, format 1:1, max 2MB</label>
                </div>
                <div>
                    <label for="imie">Imię<a class="gwiazdka">*</a></label><br>
                    <input type="text" id="imie" name="imie" placeholder="Imię" v-model="imie" required/>
                </div>
                <div>
                    <label for="nazwisko">Nazwisko<a class="gwiazdka">*</a></label><br>
                    <input type="text" id="nazwisko" name="nazwisko" placeholder="Nazwisko" v-model="nazwisko" required/>
                </div>
                <div>
                    <label for="email">Email<a class="gwiazdka">*</a></label><br>
                    <input type="text" id="email" name="email" placeholder="Email" v-model="email" required/>
                </div>
                <div>
                    <label for="data">Data urodzenia</label><br>
                    <input type="date" id="data" name="data" v-model="data"/>
                </div>
                <div>
                    <label for="plec">Płeć</label><br>
                    <select id="plec" name="plec" v-model="plec">
                        <option value="" hidden>Płeć</option>
                        <option value="male">Mężczyzna</option>
                        <option value="female">Kobieta</option>
                        <option value="other">Inne</option>
                    </select>
                </div>
                <div class="border"></div>
                <div>
                    <label for="wyksztalcenie">Wykształcenie<a class="gwiazdka">*</a></label><br>
                    <select id="wyksztalcenie" name="wyksztalcenie" v-model="wyksztalcenie" required>
                        <option value="" disabled selected hidden>Wykształcenie</option>
                        <option value="student">Student</option>
                        <option value="wyższe">Wyższe</option>
                        <option value="doktorant">Doktorant</option>
                        <option value="doktor">Doktor</option>
                        <option value="profesor">Profesor</option>
                    </select>
                </div>
                <!-- <div>
                    <label for="zawod">Zawód</label><br>
                    <input type="text" id="zawod" name="zawod" placeholder="Zawód" v-model="zawod"/>
                </div> -->
                <div>
                    <label for="doswiadczenie">Doświadczenie</label><br>
                    <input type="text" id="doswiadczenie" name="doswiadczenie" placeholder="Doświadczenie" v-model="doswiadczenie"/>
                </div>
                <div class="border"></div>
                <div>
                    <label for="telefon">Telefon<a class="gwiazdka">*</a></label><br>
                    <input type="text" id="telefon" name="telefon" placeholder="Telefon" v-model="telefon" required/>
                </div>
                <div>
                    <label for="skype">Skype</label><br>
                    <input type="text" id="skype" name="skype" v-model="skype" placeholder="Skype"/>
                </div>
                <div>
                    <label for="discord">Discord</label><br>
                    <input type="text" id="discord" name="discord" v-model="discord" placeholder="Discord"/>
                </div>
                <div>
                    <label for="zoom">Zoom</label><br>
                    <input type="text" id="zoom" name="zoom" v-model="zoom" placeholder="Zoom"/>
                </div>
                <div>
                    <label for="facebook">Facebook</label><br>
                    <input type="text" id="facebook" name="facebook" v-model="facebook" placeholder="Facebook"/>
                </div>
                <div class="border"></div>
                <div>
                  <label for="miasto">Miasto<a class="gwiazdka">*</a></label><br>
                  <select id="miasto" name="miasto" v-model="miasto" required>
                      <option value="" hidden selected disabled>Dodaj miasto</option>
                      <option v-for="(element, index) in miasta" :key="index" :value="index">{{ element }}</option>
                  </select>
                </div>
                <div class="border"></div>
                <div class="settings-form-area" id="duzy">
                    <label for="bio">O mnie</label><br>
                    <textarea id="bio" name="bio" placeholder="Opowiedz o sobie" v-model="info"></textarea>
                </div>
                
                <div class="settings-form-button-div" >
                    <button type="submit" id="submit" name="submit">Zapisz</button>
                </div>
            </form>
            <form class="settings-form settings-haslo" v-if="numer === 2">
                <div>
                    <label for="aktualneHaslo">Aktualne hasło</label><br>
                    <input type="password" id="aktualneHaslo" name="aktualneHaslo" placeholder="Aktualne hasło" required/>
                </div>
                <div>
                    <label for="noweHaslo">Nowe hasło</label><br>
                    <input type="password" id="noweHaslo" name="noweHaslo" placeholder="Nowe hasło" required/>
                </div>
                <div>
                    <label for="potwierdzNoweHaslo">Potwierdź nowe hasło</label><br>
                    <input type="password" id="potwierdzNoweHaslo" name="potwierdzNoweHaslo" placeholder="Potwierdź nowe hasło" required/>
                </div>
                
                <div class="settings-form-button-div settings-haslo-button-div" >
                    <button type="submit" id="submit" name="submit">Zapisz</button>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
    import {ref, reactive, toRefs, inject} from "vue";
    import axios from 'axios';
import router from "@/router";
    var numer = ref(1);
    function nr1()
    {
        numer.value=1;
        console.log(numer.value);
    }
    function nr2()
    {
        numer.value=2;
        console.log(numer.value);
    }
    var miasta = ref([]);
    function getCookie(cname) 
    {
      let name = cname + "=";
      let ca = document.cookie.split(';');
      for(let i = 0; i < ca.length; i++) {
          let c = ca[i];
          while (c.charAt(0) == ' ') {
          c = c.substring(1);
          }
          if (c.indexOf(name) == 0) {
          return c.substring(name.length, c.length);
          }
      }
      return "";
    }
    var formularz = reactive({
        userId: getCookie('userId'),
        awatar: "",
        imie: "",
        nazwisko: "",
        email: "",
        data: "",
        plec: "",
        wyksztalcenie: "",
        doswiadczenie: "",
        zawod: "",
        telefon: "",
        skype: "",
        discord: "",
        zoom: "",
        facebook: "",
        miasto: "",
        info: "",
    })
    
    var user;
    export default
    {
        name: 'stronaKontaUstawienia',
        setup ()
        {

            return{nr1,nr2,numer, ...toRefs(formularz), miasta}
        },
        mounted()
        {
          const endpoint = inject('g_endpoint');
          formularz.userId = getCookie('userId');
          const fetchData = async () => 
          {
            try 
            {
              const response = await axios.get(`${endpoint}api/users/${formularz.userId}/`);
              user = response.data;
              formularz.awatar = user.avatar;
              formularz.imie = user.name;
              formularz.nazwisko = user.surname;
              formularz.email = user.email;
              formularz.data = user.date_of_birth;
              formularz.plec = user.sex;
              formularz.wyksztalcenie = user.education;
              formularz.doswiadczenie = user.experience;
              formularz.zawod = user.occupation;
              formularz.telefon = user.phone_number;
              formularz.skype = "";
              formularz.discord = "";
              formularz.zoom = "";
              formularz.facebook = "";
              formularz.miasto = user.occupation;
              formularz.info = user.description;
              console.log(user);

              const response2 = await axios.get(`${endpoint}api/regions/`);
              let miasta1 = response2.data;
              miasta1.forEach(element => {
                miasta.value.push(element.city);
              });
            }
            catch (error)
            {
              console.error(error);
            }
          };
          fetchData();
          function postuj(event)
          {
            
            event.preventDefault();
            const telefonRegex = /^\d{9}$/; // Wyrażenie regularne dla 9 cyfr

            if (!telefonRegex.test(formularz.telefon)) {
              console.error("Numer telefonu musi zawierać 9 cyfr.");

              const phoneField = document.getElementById("telefon");
              if (phoneField) {
                phoneField.scrollIntoView({ behavior: "smooth", block: "center" });
              }
              return;
            }
            console.log(toRefs(formularz));
            var cos = 
            {
              name: formularz.imie,
              surname: formularz.nazwisko,
              //avatar: null,
              date_of_birth: formularz.data,
              sex: formularz.plec,
              email: formularz.email,
              phone_number: formularz.telefon,
              description: formularz.info,
              education: formularz.wyksztalcenie,
              occupation: formularz.miasto,
              experience: formularz.doswiadczenie,
            }
            let cosG = JSON.stringify(cos);
            console.log(cosG);
            axios
            .put(`${endpoint}api/users/${formularz.userId}/`, cosG, {
            withCredentials: true,
            headers: {
                'X-CSRFToken': `${getCookie('tokenCSRF')}`,
                'Authorization': `Token ${getCookie('tokenDRF')}`,
                'Content-Type': "application/json"
            }
            })
            .then((response) => 
            {
                console.log(response);
                router.push('/konto');
            })
            .catch(error => 
            {
                console.log(error);
            })
          }
          document.getElementById('form').addEventListener('submit', postuj);
          return{postuj}
        }
    }
</script>

<style lang="scss" scoped>
  @import '../styleFolder/style.scss';
  *
  {
    box-sizing: border-box;
  }
  .gwiazdka
  {
    color: $warning-color;
  }
  select:invalid
  {
    color: $border-color2 !important;
    opacity: 1;
  }
  input:invalid
  {
    color: $border-color2 !important;
    opacity: 1;
  }
  input::placeholder
  {
    color: $border-color2 !important;
    opacity: 1;
  }
  .prawy 
  {
    & h3
    {
      margin-top: 5vh;
      width: 100%;
      text-align: center;
    }
    & h2 
    {
      width: 100%;
      text-align: center;
      font-size: 2rem;
    }
  }
  .active 
  {
    color: white;
    background-color: $main-color;
  }
  option 
  {
    color: $font-color;
  }
  .settings 
  {
    width: 100%;
    border: solid 1px $border-color;
    padding: 1vw;
    padding-top: 3vh;
    padding-bottom: 3vh;
    margin-top: 3vh;
    border-radius: 1vh;
    box-shadow: 0.5vh 0.5vh 1vh 1vh $border-color;
    &-menu 
    {
      display: flex;
      flex-direction: row;
      margin-bottom: 5vh;
      background-color: $border-color;
      margin-left: -1vw;
      margin-right: -1vw;
      margin-top: -3vh;
      border-top-right-radius: 1vh;
      &-div1 
      {
        border-top-left-radius: 1vh;
      }
      &-div2 
      {
        border-top-right-radius: 1vh;
      }
      & div 
      {
        width: 50%;
        height: 8vh;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: 0.2s ease-out;
        &:hover 
        {
          background-color: $main-color;
          color: white;
        }
      }
    }
    &-haslo 
    {
      display: flex;
      justify-content: center !important;
      & div {
        width: 100% !important;
        padding: 1%;
      }
      & button {
        width: 100% !important;
      }
      &-button-div 
      {
        text-align: center !important;
      }
    }
    &-form 
    {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between;
      row-gap: 5vh;
      & .border 
      {
        width: 100%;
        height: 0px;
      }
      &-button-div 
      {
        text-align: center;
        width: 100% !important;
        flex-grow: 2;
        & button {
          width: 50%;
          color: white;
          padding: 1.5%;
          background-color: $main-color;
          border-radius: 100vw;
          border: 1px solid white;
          cursor: pointer;
          font-size: 1rem;
          font-weight: bold;
          transition: 0.3s;
          text-decoration: none;
          &:hover {
            color: $main-color;
            background-color: white;
            border: 1px solid $main-color;
          }
        }
      }
      & div {
        width: 100%;
      }
      & .img-div {
        width: 100%;
        text-align: center;
        & input {
          display: none;
        }
        & label {
          width: 100%;
          & img {
            width: 20vh;
            height: 20vh;
            cursor: pointer;
            border-radius: 100vw;
          }
        }
      }
      & input,
      select {
        margin-top: 2vh;
        width: 100%;
        height: 75%;
        background-color: white;
        border-radius: 100vw;
        border: 1px solid $border-color;
        padding-left: 5%;
        font-size: 1rem;
        color: $font-color;
        outline: none;
      }
      & select {
        width: 100%;
        height: 75%;
      }
      & textarea {
        width: 100%;
        overflow: hidden;
        overflow-wrap: break-word;
        resize: none;
        height: 20vh;
        margin-top: 2vh;
        border-radius: 30px;
        border: 1px solid $border-color;
        padding: 2vh;
      }
    }
  }
  #duzy 
  {
    width: 100%;
  }
    
    @media only screen and (min-width: 600px) 
    {
      .prawy 
      {
        & h3 
        {
          margin-top: 5vh;
        }
      }
      .active 
      {
        color: white;
        background-color: $main-color;
      }
      option 
      {
        color: $font-color;
      }
      .settings 
      {
        &-form 
        {
          & .border {
            width: 100%;
            height: 0px;
          }
          & div {
            width: 48%;
          }
        }
      }
    }

    @media only screen and (min-width: 1000px) 
    {
      .settings 
      {
        &-haslo {
          & div {
            width: 51% !important;
            padding: 1%;
          }
          & button {
            width: 51% !important;
          }
        }
        &-form 
        {
          & .border {
            width: 100%;
            height: 0px;
            margin-top: 5vh;
            margin-bottom: 1vh;
          }
          & div {
            width: 48%;
          }
        }
      }
    }
</style>