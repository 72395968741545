import { createStore } from 'vuex';

const store = createStore({
  state: {
    isLoggedIn: localStorage.getItem('isLoggedIn') === 'true',
    tokenCSRF: localStorage.getItem('tokenCSRF')
  },
  mutations: {
    setLoggedIn(state, value) {
      state.isLoggedIn = value;
      localStorage.setItem('isLoggedIn', value ? 'true' : 'false');
    },
    setTokenCSRF(state, token) {
      state.tokenCSRF = token;
      localStorage.setItem('tokenCSRF', token);
    },
  },
  actions: {
    checkLoginStatus({ commit }) {
      const cookies = document.cookie.split('; ');
      for (const cookie of cookies) {
        const [name, value] = cookie.split('=');
        if (name === 'czyZalogowano' && value === 'true') {
          commit('setLoggedIn', true);
          break;
        }
      }
    },
    login({ commit }) {
      document.cookie = 'czyZalogowano=true; expires=Fri, 31 Dec 9999 23:59:59 GMT; path=/';
      commit('setLoggedIn', true);
    },
    logout({ commit }) {
      document.cookie = 'czyZalogowano=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/';
      commit('setLoggedIn', false);
    },
    setCSRFToken({ commit }, token) {
      document.cookie = `tokenCSRF=${token}; expires=Fri, 31 Dec 9999 23:59:59 GMT; path=/`;
      commit('setTokenCSRF', token);
    },
  },
});

export default store;
