<template>
    <div class="notFound">
        <div class="main">
            <h1>Nie znaleźliśmy tej strony...</h1>
            <p>Strona, której szukasz nie istnieje. Prawdopodonie została usunięta lub jest czasowo niedostępna</p>
            <RouterLink to="/">Wróć do strony głównej</RouterLink>
        </div>
        
    </div>
</template>

<script>
      export default 
      {
        setup()
        {
            document.title = 'Nie znaleziono strony';
        }
      }

</script>

<style lang="scss" scoped>
    @import './styleFolder/style.scss';
    .notFound
    {
        margin-top: 10vh;
        min-height: 80vh;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .main
    {
        width: 60vw;
        height: 30vh;
        display: flex;
        flex-direction: column;
        gap: 5vh;
    }
    a
    {
        color: white;
        width: 20%;
        padding: 1%;
        padding-left: 10%;
        padding-right: 10%;
        background-color: $main-color;
        text-align: center;
        border-radius: 75px;
        border: none;
        cursor: pointer;
        font-size: 1vw;
        font-weight: bold;
        transition: 0.3s ease-out;
        text-decoration: none;
        &:hover
        {
            color: $main-color;
            background-color: white;
            border: 1px solid $main-color;
        }
    }
    
    @media only screen and (min-width: 600px) 
    {

    }

    @media only screen and (min-width: 1000px) 
    {

    }
</style>