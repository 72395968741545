import { createApp } from 'vue';
import store from './store';
import router from './router';
import App from './App.vue';

const app = createApp(App);

const isLoggedIn = localStorage.getItem('isLoggedIn') === 'true';
store.commit('setLoggedIn', isLoggedIn);

//const g_endpoint = "http://localhost:8000/";
const g_endpoint = "https://gigakorki.pl:8001/";

app.provide('g_endpoint', g_endpoint);

app.use(store);
app.use(router);

//const isLoggedIn = localStorage.getItem('isLoggedIn') === 'true';
//store.commit('setLoggedIn', isLoggedIn);

app.mount('#app');
